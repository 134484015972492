import React, { Component } from 'react';
import classnames from 'classnames';

import { connect } from '../store';
import MarqueeRows from '../components/MarqueeRows';

import css from './Splash.css';

let content = {
	rows: 22,
	lines: [
		{
			text: 'noa    noa    noa    noa    noa    noa    ',
			size: 2,
			position: 14,
			speed: 900,
			color: 'rgb(255,255,255)'
		},
		{
			text: 'merced 142 c   santiago   +   ',
			size: 1,
			position: 16,
			speed: -500,
			color: 'rgb(255,255,255)'
		}
	]
};

class Splash extends Component {
	componentDidMount() {}

	render() {
		return (
			<div className={css.root}>
				<MarqueeRows content={content} />
			</div>
		);
	}
}

export default Splash;
