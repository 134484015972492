import React, { Component } from 'react';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { actions } from '../../store';
import css from './Login.css';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			input: ''
		};
		this.inputChanged = this.inputChanged.bind(this);
		this.buttonClicked = this.buttonClicked.bind(this);
	}

	inputChanged(e, name, value) {
		this.setState({ input: value });
	}

	buttonClicked() {
		console.log(this.state);
		actions.login(this.state.input.toLowerCase());
	}

	render() {
		const { loginError } = this.props;
		return (
			<div className={css.root}>
				<Input
					label="admin token"
					autoCorrect="off"
					autoCapitalize="off"
					spellCheck="false"
					name="adminToken"
					value={this.state.input}
					onChange={this.inputChanged}
					onSubmit={this.buttonClicked}
				/>
				{loginError && <p className={css.error}>{loginError}</p>}
				<Button onClick={this.buttonClicked}>log in</Button>
			</div>
		);
	}
}

export default Login;
