import React, { Component } from 'react';
import dayjs from 'dayjs';

import CCapture from '../../lib/ccapture-1.0.9';
import Input, { InputRow } from '../../components/Input';
import Button from '../../components/Button';
import Radio, { RadioGroup } from '../../components/Radio';
import { List } from 'immutable';
import { connect, actions } from '../../store';
import Authorize from './Authorize';
import css from './ProgrammingScreen.css';
import {
	random,
	formatDate,
	onAndAfterDate,
	versionedName,
	randomGlyphWidths,
	getGlyphs,
	getGlyphsWidth,
	drawGlyphs,
	drawRepeatedGlyphs,
	FrameRate,
	calculateSpeeds
} from '../../utils';
import { getSimulatedScreenInfo, drawSimulatedScreen } from './utils';
import memoize from 'memoize-one';

const fontSize = 7;
const screenWidth = 96;
const screenHeight = 80;
const speed = 2;
const gap = 0;
const rightColPos = 30;

class ProgrammingScreen extends Component {
	constructor(props) {
		super(props);
		this.loadLineupClicked = this.loadLineupClicked.bind(this);
		this.inputChanged = this.inputChanged.bind(this);
		this.lineChanged = this.lineChanged.bind(this);
		this.exportVideo = this.exportVideo.bind(this);
		this.draw = this.draw.bind(this);

		// Memoized functions
		this.getGlyphs = memoize(getGlyphs);
		this.getGlyphsWidth = memoize(getGlyphsWidth);
		this.getIndexOfLongestWidth = memoize(this.getIndexOfLongestWidth);
		this.calculateSpeeds = memoize(calculateSpeeds);

		this.orgCanvasRef = React.createRef();
		this.simulatedCanvasRef = React.createRef();

		this.state = {
			date: dayjs().format('YYYY-MM-DD'),
			topText: 'noa noa noa noa ', // repeat to get different glyphs widths
			bottomText: 'noanoa.club noanoa.club ', // repeat to get different glyphs widths
			ledCanvasWidth: 'window',
			videoExportRunning: false,
			leftLines: List(['hoy', 'mana', 'pasa', 'juev', 'vier', 'saba', 'chao']),
			rightLines: List([
				'linea uno',
				'linea dos',
				'linea tres',
				'linea cuatro',
				'linea cinco',
				'linea seis',
				'linea siete'
			])
		};

		this.frameRate = new FrameRate(15, this.draw);
		this.screenInfo = getSimulatedScreenInfo(screenWidth, screenHeight, 3, 10);
		this.stringsDidUpdate();
	}

	// Make an array that we can pass to the memoized function. We cannot
	// create this array in every render, because then equality fails, so we
	// create it here when the component changes.
	stringsDidUpdate() {
		const allowedWidths = [490, 1400];
		const { topText, bottomText } = this.state;
		const rightLines = this.state.rightLines.toJS();
		const leftLines = this.state.leftLines.toJS();
		this.stringList = [topText, bottomText].concat(rightLines, leftLines);
		this.stringListWidths = [
			randomGlyphWidths(topText, allowedWidths),
			randomGlyphWidths(bottomText, allowedWidths)
		].concat(Array.from(rightLines, v => randomGlyphWidths(v, allowedWidths)), Array.from(leftLines, v => 280));

		this.resetCounters();
	}

	resetCounters() {
		// Create counters to hold x for each scrolling line
		// 0: topText
		// 1: bottomText
		// 2-8: rightLines
		this.counters = [];
		for (let i = 0; i < 9; i++) {
			this.counters.push(0);
		}
	}

	getIndexOfLongestWidth(widthList) {
		let maxi = 0;
		for (let i = 0; i < this.counters.length; i++) {
			if (widthList[i] > widthList[maxi]) {
				maxi = i;
			}
		}
		return maxi;
	}

	componentDidMount() {
		this.orgCtx = this.orgCanvasRef.current.getContext('2d', { alpha: false });
		this.simulatedCtx = this.simulatedCanvasRef.current.getContext('2d', { alpha: false });
		actions.loadLineup();
		if (this.props.smallFont) {
			this.frameRate.start();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// Start drawing when the font is loaded
		if (!prevProps.smallFont && this.props.smallFont) {
			this.frameRate.start();
		}

		// Put lineup.json into left and right lines when loaded
		if (prevProps.lineup !== this.props.lineup) {
			const events = this.props.lineup.toJS().events;
			this.setState({
				leftLines: List(
					onAndAfterDate(events, 'date', this.state.date)
						.map(e => formatDate(e.date).toUpperCase())
						.concat(['', '', '', '', '', '', ''])
						.slice(0, 7)
				),
				rightLines: List(
					onAndAfterDate(events, 'date', this.state.date)
						.map(e =>
							// title is an array when there is more than one artist.
							Array.isArray(e.title)
								? e.title.map(t => t.replace(/\s+/g, ' ').trim()).join('  ') + '  '
								: e.title.trim() + '  '
						)
						.concat(['', '', '', '', '', '', ''])
						.slice(0, 7)
				)
			});
		}
		this.stringsDidUpdate();
	}

	inputChanged(event, name, value) {
		this.setState({
			[name]: value
		});
	}

	lineChanged(event, name, value) {
		const split = name.split('-');
		const key = split[0];
		const index = parseInt(split[1]);
		this.setState({
			[key]: this.state[key].set(index, value)
		});
	}

	exportVideo() {
		this.resetCounters();
		this.frameRate.disable();
		this.capturer = new CCapture({
			format: 'webm',
			framerate: this.frameRate.fps,
			name: versionedName(`programming-${this.state.date}`)
		});
		this.capturer.start();
		this.setState({
			videoExportRunning: true
		});
	}

	loadLineupClicked() {
		actions.loadLineup();
	}

	draw() {
		const { smallFont } = this.props;
		const { leftLines, rightLines } = this.state;

		const scaledFontSize = fontSize * (smallFont.unitsPerEm / smallFont.capHeight);
		const glyphList = this.getGlyphs(this.stringList, smallFont, this.stringListWidths);
		const widthList = this.getGlyphsWidth(glyphList, scaledFontSize, gap);

		// Draw black background
		this.orgCtx.fillStyle = 'black';
		this.orgCtx.fillRect(0, 0, screenWidth, screenHeight);

		// Draw top text
		this.orgCtx.fillStyle = 'white';
		drawRepeatedGlyphs(this.orgCtx, glyphList[0], this.counters[0], fontSize + 1, scaledFontSize, screenWidth, {
			gap,
			round: true
		});

		// Draw top line at half pixel for clarity
		this.orgCtx.strokeStyle = 'white';
		this.orgCtx.beginPath();
		this.orgCtx.moveTo(0, 9.5);
		this.orgCtx.lineTo(screenWidth, 9.5);
		this.orgCtx.stroke();

		const yStart = 13 + fontSize;

		// Draw right lines
		for (let i = 0; i < 7; i++) {
			const offseti = i + 2;
			drawRepeatedGlyphs(
				this.orgCtx,
				glyphList[offseti],
				this.counters[offseti],
				yStart + i * (fontSize + 1),
				scaledFontSize,
				screenWidth,
				{ gap, round: true }
			);
		}

		// Draw black background for left lines
		this.orgCtx.fillStyle = 'black';
		this.orgCtx.fillRect(0, 13, rightColPos, screenHeight);

		// Draw left lines
		this.orgCtx.fillStyle = 'white';

		for (let i = 0; i < 7; i++) {
			const offseti = i + 9;
			drawGlyphs(this.orgCtx, glyphList[offseti], 0, yStart + i * (fontSize + 1), scaledFontSize);
		}

		// Draw bottom text
		drawRepeatedGlyphs(this.orgCtx, glyphList[1], this.counters[1], screenHeight - 1, scaledFontSize, screenWidth, {
			gap,
			round: true
		});

		// Draw bottom line at half pixel for clarity
		this.orgCtx.strokeStyle = 'white';
		this.orgCtx.beginPath();
		this.orgCtx.moveTo(0, screenHeight - 9.5);
		this.orgCtx.lineTo(screenWidth, screenHeight - 9.5);
		this.orgCtx.stroke();

		if (this.state.videoExportRunning) {
			this.capturer.capture(this.orgCanvasRef.current);
		}

		const frame = this.orgCtx.getImageData(0, 0, this.screenInfo.cols, this.screenInfo.rows);
		drawSimulatedScreen(this.screenInfo, frame, this.simulatedCtx);

		// Find speeds based on longest width. You can change this
		// to a specific i to calculate rows speeds based on that.
		const longesti = this.getIndexOfLongestWidth(widthList);
		const speeds = this.calculateSpeeds(speed, widthList, widthList[longesti], true);

		// update all counters
		for (let i = 0; i < this.counters.length; i++) {
			this.counters[i] -= speeds[i];
			const rowX = this.counters[i] + widthList[i];
			if (rowX <= 0) {
				if (this.state.videoExportRunning && i === longesti) {
					this.capturer.stop();
					this.capturer.save();
					this.frameRate.enable();
					this.setState({
						videoExportRunning: false
					});
				}
				this.counters[i] = rowX;
			}
		}
	}

	render() {
		const leftLines = this.state.leftLines.toJS();
		const rightLines = this.state.rightLines.toJS();

		const lines = this.state.leftLines.toJS().map((leftLine, i) => {
			return (
				<div key={`l-${i}`} className={css.lineInput}>
					<Input
						extraClasses={css.leftInput}
						label={`Line ${i + 1}`}
						name={`leftLines-${i}`}
						value={leftLine}
						onChange={this.lineChanged}
					/>
					<Input
						extraClasses={css.rightInput}
						label={`Line ${i + 1} scroll`}
						name={`rightLines-${i}`}
						value={rightLines[i]}
						onChange={this.lineChanged}
					/>
				</div>
			);
		});

		return (
			<Authorize>
				<div className={css.root}>
					<h3>programming screen</h3>

					<Input label="top text" name="topText" value={this.state.topText} onChange={this.inputChanged} />
					{lines}
					<Input
						label="bottom text"
						name="bottomText"
						value={this.state.bottomText}
						onChange={this.inputChanged}
					/>
					<Input
						style={{ width: 700, marginTop: 50 }}
						type="date"
						name="date"
						value={this.state.date}
						onChange={this.inputChanged}
					/>

					<Button onClick={this.loadLineupClicked}>fill from lineup</Button>
					<div style={{ width: 25 }} />

					<RadioGroup>
						<Radio
							label="Scale to fit window"
							name="ledCanvasWidth"
							value="window"
							checked={this.state.ledCanvasWidth === 'window'}
							onChange={this.inputChanged}
						/>
						<span style={{ marginLeft: 20 }} />
						<Radio
							label="Original Size"
							name="ledCanvasWidth"
							value="original"
							checked={this.state.ledCanvasWidth === 'original'}
							onChange={this.inputChanged}
						/>
					</RadioGroup>
					<canvas width={this.screenInfo.cols} height={this.screenInfo.rows} ref={this.orgCanvasRef} />
					<div className={css[this.state.ledCanvasWidth]}>
						<canvas
							width={this.screenInfo.width}
							height={this.screenInfo.height}
							ref={this.simulatedCanvasRef}
						/>
					</div>
					<Button onClick={this.exportVideo} disabled={this.state.videoExportRunning}>
						{this.state.videoExportRunning ? 'Exporting...' : 'Start export'}
					</Button>
				</div>
			</Authorize>
		);
	}
}

export default connect(({ lineup, smallFont }) => ({ lineup, smallFont }))(ProgrammingScreen);
