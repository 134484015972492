import { random } from './utils';
import Case from 'case';

export const info = {
	rows: 27,
	lines: [
		{
			text: 'merced 142, santiago de chile',
			size: 1,
			position: 4,
			speed: 100,
			reverse: true
		},
		{
			text: 'club',
			size: 1,
			position: 5,
			speed: 125
		},
		{
			text: 'martes a sabado 11 pm - 5 am',
			size: 1,
			position: 7,
			width: 300,
			speed: 75,
			reverse: true
		},
		{
			text: 'bar',
			size: 1,
			width: 1400,
			position: 9,
			speed: 75,
			reverse: true
		},
		{
			text: 'martes a sabado 7 pm - 1 am',
			size: 1,
			position: 10,
			width: 300,
			speed: 125,
			reverse: true
		},
		{
			text: 'booking info',
			size: 1,
			position: 13,
			speed: 0
		},
		{
			text: 'pia@faunaprod.com',
			size: 1,
			position: 14,
			speed: 50
		},
		{
			text: 'noa noa noa',
			size: 1,
			position: -1,
			speed: 75,
			reverse: true
		}
	]
};

export let events = [
	{
		date: '04/09/2019',
		title: 'ojos bien cerrados   ',
		ticketsUrl: `https://www.puntoticket.com/evento/noa-noa-ojos-bien-cerrados`,
		tickets: [
			{
				name: 'preventa',
				price: '5.000'
			},
			{
				name: 'puerta',
				price: '10.000'
			}
		]
	},
	{
		date: '04/09/2019',
		title: 'novamateria   ',
		subtitle: 'kleinepia   ',
		background: '#333',
		color: 'white',
		tickets: [
			{
				name: 'preventa',
				price: '5.000',
				url: `https://www.puntoticket.com/evento/noa-noa-ojos-bien-cerrados`
			},
			{
				name: 'puerta',
				price: '10.000'
			}
		]
	}
];
events = events.concat(events, events);

export default { events, info };
