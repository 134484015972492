import React, { Component, Fragment } from 'react';
import { connect, actions } from '../store';
import { info } from '../data';

import Marquee from '../components/Marquee';

import css from './Bar.css';

class Bar extends Component {
	componentDidMount() {
		actions.loadBar('barPapel', { isPublic: true });
	}
	render() {
		const { winWidth, winHeight, menu } = this.props;

		return (
			<div className={css.root}>
				<div style={{ height: (winHeight / 33) * 3 }} />

				<div className={css.menu}>
					{menu.groups &&
						menu.groups.map((group, gIndex) => (
							<dl key={`group-${gIndex}`} className={css.group}>
								<h3 className={css.title}>{group.title}</h3>
								{group.items.map((item, iIndex) => (
									<Fragment key={`item-${iIndex}`}>
										<dt className={css.name}>{item.name}</dt>{' '}
										{item.description && (
											<dd className={css.description}>
												{item.description.split('\n').map((l, i, d) => {
													return (
														<Fragment key={`line-${i}`}>
															{l}
															{i < d.length - 1 ? <br /> : null}
														</Fragment>
													);
												})}
											</dd>
										)}
									</Fragment>
								))}
							</dl>
						))}
				</div>
				<div style={{ position: 'fixed', bottom: 10 }}>
					<Marquee
						text={'bar  noa  noa  bar  noa  noa  '}
						speed={400}
						referenceWidth={800}
						inSvg={false}
						width={winWidth}
						size={2}
						height={(winHeight / 33) * 2}
					/>
				</div>
				<div style={{ height: (winHeight / 33) * 3 }} />
			</div>
		);
	}
}

export default connect(({ winWidth, winHeight, barPapel }) => ({ winWidth, winHeight, menu: barPapel.toJS() }))(Bar);
