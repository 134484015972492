import React, { Component, Fragment, createRef } from 'react';
import { Redirect } from 'react-router-dom';
import domHelpers from 'dom-helpers';
import classnames from 'classnames';
import { Map, List, fromJS, isImmutable, isEmpty } from 'immutable';
import CCapture from '../../lib/ccapture-1.0.9';
import Case from 'case';
import memoize from 'memoize-one';

import {
	formatDate,
	versionedName,
	filePath,
	randomGlyphWidths,
	getGlyphs,
	getGlyphsWidth,
	FrameRate
} from '../../utils';
import { svgToCanvas, findActiveObjects, getObjectGeom } from './utils';
import download from '../../lib/download';

import { actions, connect } from '../../store';
import Marquee from '../../components/Marquee';
import GeneratorInfo from '../../components/GeneratorInfo';
import ActiveObjectEditor from '../../components/ActiveObjectEditor';
import Toolbar from '../../components/Toolbar';
import Button from '../../components/Button';

const { height, ownerWindow } = domHelpers;

import css from './Generator.css';

const fromPercent = (percent = 0, total) => {
	return (percent * total) / 100;
};

const Status = ({ children, black }) => (
	<div style={black ? { color: 'black' } : {}} className={css.status}>
		{children}
	</div>
);

class Generator extends Component {
	constructor(props) {
		super(props);

		this.step = this.step.bind(this);
		this.frameRate = new FrameRate(60, this.step);

		this.state = {
			step: 0,
			data: false,
			videoExportRunning: false,
			videoLength: 0,
			activeObject: false
		};

		this.setInitialData = this.setInitialData.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onInsert = this.onInsert.bind(this);
		this.onPngExport = this.onPngExport.bind(this);
		this.onSvgExport = this.onSvgExport.bind(this);
		this.onVideoExport = this.onVideoExport.bind(this);
		this.saveVideoFrame = this.saveVideoFrame.bind(this);
		this.saveData = this.saveData.bind(this);
		this.throttledSaveData = this.throttledSaveData.bind(this);
		this.onArtworkClick = this.onArtworkClick.bind(this);
		this.computeObjects = memoize(this.computeObjects);
		this.processData = memoize(this.processData);
		this.svgRef = React.createRef();
		this.canvasRef = React.createRef();
	}

	componentDidMount() {
		this.frameRate.start();
		const { match, history } = this.props;
		actions.loadArtworkData(match.params.slug).then(data => {
			this.setInitialData();
		});
		actions.loadArtworkAssets(match.params.slug);
	}

	componentWillUnmount() {
		this.frameRate.stop();
		window.removeEventListener('resize', this.measure);
	}
	componentDidUpdate(prevProps) {
		const { slug } = this.props.match.params;
		if (!prevProps.font && this.props.font) {
			this.processData(this.getCurrentData());
		}
		if (
			prevProps.artworks &&
			!prevProps.artworks.getIn([slug, 'data']) &&
			this.props.artworks.getIn([slug, 'data'])
		) {
			this.setInitialData();
		}
	}
	setInitialData() {
		const { match, artworks, font } = this.props;
		const artwork = artworks.find((v, k) => k == match.params.slug);
		if (!artwork) {
			const name = match.params.slug.replace(/^\d+/).trim();
			this.setState({ data: defaultArtworkData(name, Date.now(), font) });
		} else if (artwork.has('data')) {
			this.setState({ data: this.processData(artwork.get('data')) });
		}
	}

	step() {
		const data = this.getCurrentData().toJS();

		// Update framerate if needed
		if (data.frameRate && data.frameRate !== this.frameRate.fps) {
			this.frameRate.fps = data.frameRate;
		}

		if (data.running) {
			this.setState({
				step: this.state.step + 1
			});
		}
	}

	getCurrentData() {
		return this.state.data || Map({});
	}

	processData(data) {
		const { font } = this.props;
		let objects = data.get('objects') || List([]);
		const images = data.get('images') || List([]);
		const lines = data.get('lines') || List([]);
		objects = objects.concat(images, lines);

		objects = objects.map(obj => {
			if (obj.has('text')) {
				obj = obj.update('position', p => (p < 0 ? data.get('rows') + p : p));
				return obj.update('charWidths', charWidths => {
					const newCharWidths = randomGlyphWidths(obj.get('text'), font, charWidths ? charWidths.toJS() : []);
					return fromJS(newCharWidths);
				});
			} else {
				return obj;
			}
		});
		return data
			.delete('lines')
			.delete('images')
			.set('objects', objects);
	}

	getCurrentIndex() {
		const { match, artworks } = this.props;
		const artwork = artworks.find((v, k) => k == match.params.slug);
		const index = artwork && artwork.hasIn(['index', 'entries']) ? artwork.getIn(['index', 'entries']) : List([]);
		return index ? index.filter(e => /.(jpe?g|png|svg)$/.test(e.get('name'))) : index;
	}

	onArtworkClick(e) {
		const data = this.getCurrentData().toJS();
		const { activeObject } = this.state;
		const bounds = this.svgRef.current.getBoundingClientRect();
		const x = ((e.clientX - bounds.x) * 100) / bounds.width;
		const y = ((e.clientY - bounds.y) * 100) / bounds.height;
		const indexes = findActiveObjects(x, y, data);
		// here we should add a way to select other overlapping objects
		// we already know what elements match the pointer, we just need to cycle
		// through when the user clicks again and the same result comes up
		const nextActiveObject = indexes[indexes.length - 1];
		if ((activeObject && !nextActiveObject) || (activeObject && activeObject.index != nextActiveObject.index)) {
			if (
				!data.objects[activeObject.index].src &&
				(!data.objects[activeObject.index].text || data.objects[activeObject.index].text.length === 0)
			) {
				data.objects.splice(activeObject.index, 1);
				this.onUpdate(data, nextActiveObject);
			}
		}
		this.setState({
			activeObject: nextActiveObject
		});
	}

	onSelectObject(i) {
		const data = this.getCurrentData().toJS();
		const activeObject = getObjectGeom(data.objects[i], data);
		activeObject.index = i;
		this.setState({ activeObject });
	}

	onUpdate(newData, newActiveObject) {
		this.setState({ data: this.processData(fromJS(newData)) });

		if (typeof newActiveObject !== 'undefined') this.setState({ activeObject: newActiveObject });
		this.throttledSaveData();
	}

	onChange(evt, loc, value) {
		const data = this.getCurrentData();
		this.setState({
			data: this.processData(data.setIn([].concat(loc), value))
		});
		this.throttledSaveData();
	}

	onInsert(loc, obj) {
		const data = this.getCurrentData();
		loc = loc.slice(0);
		obj = isImmutable(obj) ? obj : fromJS(obj);
		const index = loc.pop();
		this.setState({
			data: this.processData(
				data.updateIn(loc, list => {
					list = isImmutable(list) ? list : fromJS(list);
					return list.insert(index, obj);
				})
			)
		});
		this.throttledSaveData();
	}

	saveData() {
		actions.saveArtworkData(this.props.match.params.slug, this.getCurrentData());
	}

	throttledSaveData() {
		clearTimeout(this.timer);
		this.timer = setTimeout(() => this.saveData(), 1000);
	}

	async onPngExport() {
		const { slug } = this.props.match.params;
		const svg = this.svgRef.current;
		const data = this.getCurrentData().toJS();
		const { height = 1000, width = 600 } = data;
		const canvas = await svgToCanvas(svg, width, height);
		download(canvas.toDataURL('image/png'), versionedName(slug, 'png'), 'image/png');
	}

	onSvgExport() {
		const { slug } = this.props.match.params;
		const svg = this.svgRef.current;
		const svgStr = new XMLSerializer().serializeToString(svg);
		download(svgStr, versionedName(slug, 'svg'), 'image/svg+xml');
	}

	async onVideoExport() {
		const { videoExportRunning } = this.state;
		const { slug } = this.props.match.params;

		if (!videoExportRunning) {
			this.frameRate.disable();
			this.capturer = new CCapture({ format: 'webm', framerate: 30, name: versionedName(slug) });
			this.capturer.start();
			this.saveVideoFrame();
		} else {
			this.frameRate.enable();
			this.capturer.stop();
			this.capturer.save();
		}

		this.setState({
			videoExportRunning: !videoExportRunning,
			videoLength: 0
		});
	}

	async saveVideoFrame() {
		const svg = this.svgRef.current;
		const data = this.getCurrentData().toJS();
		const { height = 1000, width = 600, targetLength } = data;
		const canvas = await svgToCanvas(svg, width, height, data.background === 'transparent' ? 'black' : null);
		this.capturer.capture(canvas);
		if (this.state.videoLength / 30 > targetLength) {
			this.onVideoExport();
		}
		this.setState({
			videoLength: this.state.videoLength + 1
		});
		if (this.state.videoExportRunning) {
			requestAnimationFrame(this.saveVideoFrame);
		}
	}

	computeObjects(curObjects, font, rowHeight) {
		let objects = curObjects.toJS();
		const glyphsWidth = [];
		objects = objects.map(obj => {
			if (obj.text) {
				const glyphs = getGlyphs(obj.text, font, obj.charWidths);
				glyphsWidth.push(getGlyphsWidth(glyphs, obj.size * rowHeight));
				obj.glyphs = glyphs;
				return obj;
			}
			return obj;
		});

		return { objects, glyphsWidth };
	}

	render() {
		const { font, match, adminToken, winHeight, winWidth, loading, status, artworks } = this.props;

		if (!adminToken) return <Redirect to="/" />;

		if (!font || artworks.get('loading')) return <Status>loading...</Status>;

		const slug = match.params && match.params.slug;

		if (/\s/.test(slug)) {
			return <Redirect to={`/admin/artwork/${slug.replace(/\s+/g, '-')}`} />;
		}

		const { activeObject } = this.state;
		const curData = this.getCurrentData();
		const data = curData.toJS();
		const index = this.getCurrentIndex().toJS();
		const { gutterRatio = 0.2, rows = 33, height = 1000, width = 600, running, color: baseColor } = data;

		const rowHeight = height / rows;
		const gutter = rowHeight * gutterRatio;

		const displayHeight = Math.min(winHeight * 0.9, height);
		const displayWidth = (width * displayHeight) / height;

		let elements = null;
		if (curData.has('objects')) {
			const { objects, glyphsWidth } = this.computeObjects(curData.get('objects'), font, rowHeight);
			const maxWidth = Math.max(...glyphsWidth);
			elements = objects.map((obj, index) => {
				if (obj.text) {
					const { glyphs, size, position, background, color: objColor, ...rest } = obj;
					return (
						<Marquee
							key={`line-${index}`}
							controlled={true}
							glyphs={glyphs}
							referenceWidth={maxWidth}
							step={this.state.step}
							background={background}
							inSvg={true}
							gutter={gutter}
							height={size * rowHeight}
							offsetY={position * rowHeight}
							running={running}
							width={data.width}
							color={objColor || baseColor}
							{...rest}
							style={{
								mixBlendMode: obj.blendMode
							}}
						/>
					);
				} else if (obj.src) {
					return (
						<image
							key={`image-${index}`}
							xlinkHref={obj.base64 || filePath(obj.src)}
							preserveAspectRatio={obj.crop || 'xMidYMid slice'}
							transform={`translate( ${fromPercent(obj.left, data.width)} ${fromPercent(
								obj.top,
								data.height
							)})`}
							style={{
								mixBlendMode: obj.blendMode,
								width: fromPercent(obj.width, data.width),
								height: fromPercent(obj.height, data.height)
							}}
						/>
					);
				}
			});
		}
		return (
			<Fragment>
				<div className={css.root} onClick={this.onArtworkClick}>
					{status && <Status black>{status}</Status>}
					{artworks.get('isNew') && (
						<Button invert className={css.create} onClick={() => this.saveData()}>
							create
						</Button>
					)}
					{data.objects ? (
						<Toolbar
							data={data}
							index={index}
							activeObject={activeObject}
							onSelectObject={i => this.onSelectObject(i)}
							slug={slug}
							onChange={this.onChange}
							onInsert={this.onInsert}
							onPngExport={this.onPngExport}
							onSvgExport={this.onSvgExport}
							onVideoExport={this.onVideoExport}
							videoExportRunning={this.state.videoExportRunning}
							videoLength={this.state.videoLength}
						/>
					) : null}

					<div
						className={css.canvas}
						onClick={this.onArtworkClick}
						style={{
							width: parseInt(displayWidth),
							height: parseInt(displayHeight)
						}}>
						<GeneratorInfo />
						{activeObject && (
							<Fragment>
								<ActiveObjectEditor meta={activeObject} data={data} onUpdate={this.onUpdate} />
								<GridOverlay rows={data.rows} />
							</Fragment>
						)}

						<svg
							ref={this.svgRef}
							viewBox={`0 0 ${parseInt(width)} ${parseInt(height)}`}
							width={displayWidth}
							height={displayHeight}
							style={{
								position: 'relative',
								zIndex: 20,
								backgroundColor: data.background
							}}>
							{elements}
						</svg>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default connect(({ font, adminToken, winHeight, artworks, status }) => ({
	font,
	adminToken,
	winHeight,
	artworks,
	status
}))(Generator);

const defaultArtworkData = memoize((name, date, font) => {
	const header = 'noa   ';
	const text = `${Case.lower(name).replace(/\s+/g, '   ')}   `;
	const footer = `${formatDate(date)}   +   merced 142   +   `;
	return fromJS({
		frameRate: 30,
		targetLength: 10,
		width: 1080,
		height: 1920,
		rows: 33,
		background: '#000',
		color: '#fff',
		running: true,
		objects: [
			{
				text: header,
				charWidths: randomGlyphWidths(header, font),
				size: 1,
				position: 2,
				speed: -400
			},
			{
				text: text,
				charWidths: randomGlyphWidths(text, font),
				position: 15,
				size: 3,
				speed: 300
			},
			{
				position: 30,
				text: footer,
				charWidths: randomGlyphWidths(footer, font),
				size: 1,
				speed: 200
			}
		]
	});
});

const GridOverlay = props => {
	const rows = new Array(props.rows - 1).fill(0);
	return (
		<div style={{ position: 'absolute', zIndex: 100, top: 0, left: 0, width: '100%', height: '100%' }}>
			{rows.map((e, i) => (
				<div
					key={`grid-row-${i}`}
					style={{ height: `${100 / props.rows}%`, borderBottom: '1px solid rgba(0,130,255,.4)' }}
				/>
			))}
		</div>
	);
};
