import React, { Component } from 'react';
import classnames from 'classnames';

import MarqueeRows from '../components/MarqueeRows';
import { random, shuffle } from '../utils';

import css from './Live.css';

class Live extends Component {
	render() {
		return <div className={css.root} />;
	}
}

export default Live;
