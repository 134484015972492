import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { uid } from '../utils';
import css from './Radio.css';

export class Radio extends Component {
	constructor(props) {
		super(props);
		this.state = { focus: false };
		this._id = uid('radio');
		this.handleOnChange = this.handleOnChange.bind(this);
		this.handleOnFocus = this.handleOnFocus.bind(this);
		this.handleOnBlur = this.handleOnBlur.bind(this);
	}

	handleOnChange(event) {
		const { onChange, disabled } = this.props;
		if (disabled) return;
		const { name, value } = event.target;
		onChange && onChange(event, name, value);
	}

	handleOnFocus() {
		this.setState({ focus: true });
	}

	handleOnBlur() {
		this.setState({ focus: false });
	}

	render() {
		const { checked, disabled, id = this._id, name, value, label, variant, className } = this.props;
		return (
			<div
				className={classnames(css.root, css[variant], className, {
					[css.focus]: this.state.focus,
					[css.checked]: checked,
					[css.disabled]: disabled
				})}>
				<input
					readOnly={disabled}
					className={css.input}
					checked={checked}
					id={id}
					name={name}
					onChange={this.handleOnChange}
					onFocus={this.handleOnFocus}
					onBlur={this.handleOnBlur}
					type="radio"
					value={value}
				/>
				{label && (
					<label className={css.label} htmlFor={id}>
						<span>{label}</span>
					</label>
				)}
			</div>
		);
	}
}

export default Radio;

Radio.propTypes = {
	/**
	 * Whether the radio is currently checked
	 */
	checked: PropTypes.bool,

	variant: PropTypes.string,

	className: PropTypes.string,

	/**
	 * Determines if this radio is disabled
	 */
	disabled: PropTypes.bool,

	/**
	 * String id to set on this radio, useful for labeling
	 */
	id: PropTypes.string,

	/**
	 * A label for the radio option
	 */
	label: PropTypes.string,

	/**
	 * Name to apply to the underlying input
	 */
	name: PropTypes.string,

	/**
	 * Called when this radio is clicked
	 */
	onChange: PropTypes.func,

	/**
	 * Value to apply to the underlying input
	 */
	value: PropTypes.string
};

export const RadioGroup = props => <div className={classnames(css.radioGroup, props.className)}>{props.children}</div>;
