import React, { Component } from 'react';
import dayjs from 'dayjs';

import CCapture from '../../lib/ccapture-1.0.9';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Radio, { RadioGroup } from '../../components/Radio';
import { connect, actions } from '../../store';
import Authorize from './Authorize';
import css from './MarqueeScreen.css';
import {
	formatDate,
	onAndAfterDate,
	versionedName,
	randomGlyphWidths,
	getGlyphs,
	getGlyphsWidth,
	drawRepeatedGlyphs,
	FrameRate
} from '../../utils';
import { getSimulatedScreenInfo, drawSimulatedScreen } from './utils';
import memoize from 'memoize-one';

const fontSize = 28;
const fontWidth = 280;
const margin = 2;
const screenWidth = 800;
const screenHeight = fontSize + 2 * margin;
const framerate = 20;
const speed = 4;
const gap = 0;

class MarqueeScreen extends Component {
	constructor(props) {
		super(props);
		this.loadLineupClicked = this.loadLineupClicked.bind(this);
		this.inputChanged = this.inputChanged.bind(this);
		this.exportVideo = this.exportVideo.bind(this);
		this.draw = this.draw.bind(this);

		this.frameRate = new FrameRate(framerate, this.draw);

		// Memoized functions
		this.getGlyphs = memoize(getGlyphs);
		this.getGlyphsWidth = memoize(getGlyphsWidth);

		this.orgCanvasRef = React.createRef();
		this.simulatedCanvasRef = React.createRef();

		this.state = {
			date: dayjs().format('YYYY-MM-DD'),
			speed: speed,
			text: 'enter text here   ',
			ledCanvasWidth: 'window',
			videoExportRunning: false
		};

		this.screenInfo = getSimulatedScreenInfo(screenWidth, screenHeight, 3, 10);
		this.textX = 0;
	}

	componentDidMount() {
		this.orgCtx = this.orgCanvasRef.current.getContext('2d', { alpha: false });
		this.simulatedCtx = this.simulatedCanvasRef.current.getContext('2d', { alpha: false });

		if (this.props.font) {
			this.frameRate.start();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// Start drawing when the font is loaded
		if (!prevProps.font && this.props.font) {
			actions.loadLineup();
			this.frameRate.start();
		}

		// Put lineup.json into txt when loaded
		if (prevProps.lineup !== this.props.lineup) {
			this.textX = 0;
			this.setState(this.lineupToString(this.props.lineup.toJS()));
		}

		if (prevState.preTitle !== this.state.preTitle || prevState.postTitle !== this.state.postTitle) {
			this.textX = 0;
			this.setState(this.lineupToString(this.props.lineup.toJS()));
		}

		// Start capturing
		if (!prevState.videoExportRunning && this.state.videoExportRunning) {
			this.textX = 0;
			this.frameRate.disable();
			this.capturer = new CCapture({
				format: 'webm',
				framerate,
				name: versionedName(`marquee-${this.state.date}`)
			});
			this.capturer.start();
		}
	}

	inputChanged(event, name, value) {
		this.setState({
			[name]: value
		});
	}

	exportVideo() {
		this.setState({
			videoExportRunning: !this.state.videoExportRunning
		});
	}

	loadLineupClicked() {
		actions.loadLineup();
	}

	lineupToString(lineup) {
		const baseNoa = 'noa  noa  noa  noa    ';
		let widths = randomGlyphWidths(baseNoa, this.props.font);
		const events = onAndAfterDate(lineup.events, 'date', this.state.date).slice(0, 1);
		const labels = events.map(e => {
			let title = [].concat(e.title);
			title = title.map(t => t.replace(/\s+/g, ' ').trim());
			const titleLabel = title.join(' + ');
			widths = widths.concat(widths, randomGlyphWidths(titleLabel, this.props.font));
			const { pre, post } = e;
			const preTitleLabel = this.state.preTitle && pre ? pre.trim() + ':  ' : '';
			const postTitleLabel = this.state.postTitle && post ? ' ' + post.trim() + '    ' : '    ';
			return `${preTitleLabel}${titleLabel}${postTitleLabel}`;
		});
		return { text: baseNoa + labels.join(''), glyphWidths: widths };
	}

	draw() {
		const { font } = this.props;
		const { text, glyphWidths } = this.state;
		const scaledFontSize = fontSize * (font.unitsPerEm / font.capHeight);
		const glyphs = this.getGlyphs(text, font, glyphWidths);
		const textWidth = this.getGlyphsWidth(glyphs, scaledFontSize);

		this.orgCtx.fillStyle = 'black';
		this.orgCtx.fillRect(0, 0, this.screenInfo.cols, this.screenInfo.rows);

		this.orgCtx.fillStyle = this.screenInfo.color;
		drawRepeatedGlyphs(this.orgCtx, glyphs, this.textX, fontSize + margin, scaledFontSize, screenWidth, { gap });

		if (this.state.videoExportRunning) {
			this.capturer.capture(this.orgCanvasRef.current);
		}

		const frame = this.orgCtx.getImageData(0, 0, this.screenInfo.cols, this.screenInfo.rows);
		drawSimulatedScreen(this.screenInfo, frame, this.simulatedCtx);

		this.textX -= this.state.speed;
		if (this.textX + textWidth + gap < 0) {
			if (this.state.videoExportRunning) {
				this.capturer.stop();
				this.capturer.save();
				this.frameRate.enable();
				this.setState({
					videoExportRunning: false
				});
			}
			this.textX = 0;
		}
	}

	render() {
		const { preTitle, postTitle } = this.state;
		return (
			<Authorize>
				<div className={css.root}>
					<h3>marquee screen</h3>
					<div style={{ maxWidth: 500, marginLeft: '.5rem' }}>
						<Input label="marquee text" name="text" value={this.state.text} onChange={this.inputChanged} />
						<Input
							type="range"
							min={1}
							max={20}
							label={`marquee speed`}
							name="speed"
							value={this.state.speed}
							onChange={this.inputChanged}
						/>
						<RadioGroup className={css.radioGroup}>
							<Radio
								label="Pre-title"
								name="preTitle"
								value="pretitle"
								variant="small"
								checked={preTitle === 'pretitle'}
								onChange={this.inputChanged}
								className={css.radio}
							/>
							<Radio
								label="No pre-title"
								name="preTitle"
								value=""
								variant="small"
								checked={preTitle !== 'pretitle'}
								onChange={this.inputChanged}
								className={css.radio}
							/>
						</RadioGroup>
						<RadioGroup className={css.radioGroup}>
							<Radio
								label="post-title"
								name="postTitle"
								value="posttitle"
								variant="small"
								checked={postTitle === 'posttitle'}
								onChange={this.inputChanged}
								className={css.radio}
							/>
							<Radio
								label="No post-title"
								name="postTitle"
								value=""
								variant="small"
								checked={postTitle !== 'posttitle'}
								onChange={this.inputChanged}
								className={css.radio}
							/>
						</RadioGroup>
						<Input
							style={{ width: 500, marginTop: 50 }}
							type="date"
							name="date"
							value={this.state.date}
							onChange={this.inputChanged}
						/>
						<Button wide onClick={this.loadLineupClicked}>
							fill from lineup
						</Button>
						<RadioGroup>
							<Radio
								label="fit window"
								name="ledCanvasWidth"
								value="window"
								checked={this.state.ledCanvasWidth === 'window'}
								onChange={this.inputChanged}
							/>
							<span style={{ marginLeft: 20 }} />
							<Radio
								label="original size"
								name="ledCanvasWidth"
								value="original"
								checked={this.state.ledCanvasWidth === 'original'}
								onChange={this.inputChanged}
							/>
						</RadioGroup>

						<Button onClick={this.exportVideo} disabled={this.state.videoExportRunning}>
							{this.state.videoExportRunning ? 'Exporting...' : 'Start export'}
						</Button>
					</div>
					<canvas width={this.screenInfo.cols} height={this.screenInfo.rows} ref={this.orgCanvasRef} />
					<div className={css[this.state.ledCanvasWidth]}>
						<canvas
							width={this.screenInfo.width}
							height={this.screenInfo.height}
							ref={this.simulatedCanvasRef}
						/>
					</div>
				</div>
			</Authorize>
		);
	}
}

export default connect(({ lineup, font }) => ({ lineup, font }))(MarqueeScreen);
