import React, { Component, Fragment } from 'react';
import { fromJS } from 'immutable';
import Mousetrap from 'mousetrap';

import { FiTrash2 as Trash, FiCopy as Copy, FiArrowDown as Down, FiArrowUp as Up } from 'react-icons/fi';

import { getObjectGeom } from '../pages/admin/utils';
import { blendModes, srcToBase64, filePath } from '../utils';

import classnames from 'classnames';

import Button from './Button';
import Input, { InputRow } from './Input';
import Select from './Select';
import Checkbox from './Checkbox';
import Radio from './Radio';

import css from './ActiveObjectEditor.css';

const noop = () => {};

export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.onChange = this.onChange.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onClick = this.onClick.bind(this);
		this.delete = this.delete.bind(this);
		this.duplicate = this.duplicate.bind(this);
		this.lastClick = Date.now();
		this.lastSpeed = Date.now();
	}
	componentDidMount() {
		this.setShortcuts();
	}
	componentDidUpdate(prevProps) {
		if (prevProps.meta && this.props.meta && prevProps.meta.index !== this.props.meta.index) {
			Mousetrap.reset();
			this.setShortcuts();
		}
	}
	setShortcuts() {
		const { meta, data } = this.props;
		const isText = data.objects[meta.index].text != null;
		const isImg = data.objects[meta.index].src != null;

		Mousetrap.bind('command+up', () => this.select(1));
		Mousetrap.bind('command+down', () => this.select(-1));
		Mousetrap.bind(['command+[', 'command+´'], () => this.arrange(-1));
		Mousetrap.bind(['command+]', 'command+ç'], () => this.arrange(1));
		Mousetrap.bind(['command+option+[', 'command+option+´'], () => this.arrange(-1, true));
		Mousetrap.bind(['command+option+]', 'command+option+ç'], () => this.arrange(1, true));

		if (isText) {
			Mousetrap.bind('down', () => this.textMove(1));
			Mousetrap.bind('up', () => this.textMove(-1));
			Mousetrap.bind('command+-', () => this.scale(-1));
			Mousetrap.bind(['command+plus', 'command+='], () => this.scale(1));
			Mousetrap.bind('right', () => this.speed(1));
			Mousetrap.bind('left', () => this.speed(-1));
		}
		if (isImg) {
			Mousetrap.bind('right', () => this.imgMove(0.5, 0));
			Mousetrap.bind('left', () => this.imgMove(-0.5, 0));
			Mousetrap.bind('up', () => this.imgMove(0, -0.5));
			Mousetrap.bind('down', () => this.imgMove(0, 0.5));

			Mousetrap.bind('shift+right', () => this.imgMove(5, 0));
			Mousetrap.bind('shift+left', () => this.imgMove(-5, 0));
			Mousetrap.bind('shift+up', () => this.imgMove(0, -5));
			Mousetrap.bind('shift+down', () => this.imgMove(0, 5));

			Mousetrap.bind('command+-', () => this.imgScale(-0.5, -0.5));
			Mousetrap.bind(['command+plus', 'command+='], () => this.imgScale(0.5, 0.5));
			Mousetrap.bind(['command+shift+-', 'command+shift+_'], () => this.imgScale(-5, -5));
			Mousetrap.bind(['command+shift+plus', 'command+shift+='], () => this.imgScale(5, 5));

			Mousetrap.bind('command+left', () => this.imgScale(-0.5, 0));
			Mousetrap.bind('command+right', () => this.imgScale(0.5, 0));
			Mousetrap.bind('command+up', () => this.imgScale(0, -0.5));
			Mousetrap.bind('command+down', () => this.imgScale(0, 0.5));
			Mousetrap.bind('command+shift+left', () => this.imgScale(-5, 0));
			Mousetrap.bind('command+shift+right', () => this.imgScale(5, 0));
			Mousetrap.bind('command+shift+up', () => this.imgScale(0, -5));
			Mousetrap.bind('command+shift+down', () => this.imgScale(0, 5));
		}

		Mousetrap.bind('command+d', () => this.duplicate());
		Mousetrap.bind('backspace', () => this.delete());
		Mousetrap.bind('esc', () => this.setState({ doubleClick: false }));
	}
	componentWillUnmount() {
		Mousetrap.reset();
	}
	onClick(e) {
		e.stopPropagation();
		if (!e.target.classList.contains(css.root)) return;
		const doubleClick = Date.now() - this.lastClick < 300;
		this.setState({ doubleClick });
		this.lastClick = Date.now();

		let { data, meta, onUpdate } = this.props;
		const obj = data.objects[meta.index];
		if (doubleClick && obj.src) {
			console.log(e.clientX, e.clientY);
		}
	}

	onChange(e, name, value) {
		let { data, meta, onUpdate } = this.props;
		data.objects[meta.index][name] = value;
		onUpdate(data, meta);
	}
	onDelete(e, name, value) {
		let { data, meta, onUpdate } = this.props;
		delete data.objects[meta.index][name];
		onUpdate(data, meta);
	}
	select(dir) {
		let { data, meta, onUpdate } = this.props;
		meta.index += Math.max(0, Math.min(dir, data.objects.length - 1));
		meta = { ...meta, ...getObjectGeom(data.objects[meta.index], data) };
		onUpdate(data, meta);
		return false;
	}

	textMove(dir) {
		let { data, meta, onUpdate } = this.props;
		const obj = data.objects[meta.index];
		if (!obj.text) return;

		obj.position = (data.rows + obj.position + dir) % data.rows;
		data.objects[meta.index] = obj;

		meta = { ...meta, ...getObjectGeom(obj, data) };
		onUpdate(data, meta);
		return false;
	}
	imgMove(x, y) {
		let { data, meta, onUpdate } = this.props;
		const obj = data.objects[meta.index];
		if (!obj.src) return;
		obj.left = Math.max(-1 * obj.width, Math.min(obj.left + x, 100));
		obj.top = Math.max(-1 * obj.height, Math.min(obj.top + y, 100));
		data.objects[meta.index] = obj;
		meta = { ...meta, ...getObjectGeom(obj, data) };
		onUpdate(data, meta);
		return false;
	}
	imgScale(x, y) {
		let { data, meta, onUpdate } = this.props;
		const obj = data.objects[meta.index];
		if (!obj.src) return;
		obj.width = Math.max(0, obj.width + x);
		obj.height = Math.max(0, obj.height + y);

		data.objects[meta.index] = obj;

		meta = { ...meta, ...getObjectGeom(obj, data) };
		onUpdate(data, meta);
		return false;
	}
	scale(delta) {
		let { data, meta, onUpdate } = this.props;
		const obj = data.objects[meta.index];
		obj.size = Math.max(1, obj.size + delta);
		meta = { ...meta, ...getObjectGeom(obj, data) };
		data.objects[meta.index] = obj;
		onUpdate(data, meta);
		return false;
	}
	speed(dir) {
		let { data, meta, onUpdate } = this.props;
		const obj = data.objects[meta.index];
		const factor = 40 - (Date.now() - this.lastSpeed) / 20;
		obj.speed += Math.round(dir * Math.max(1, factor));
		data.objects[meta.index] = obj;
		onUpdate(data, meta);
		this.lastSpeed = Date.now();
		return false;
	}
	arrange(dir, max) {
		let { data, meta, onUpdate } = this.props;
		const from = meta.index;
		const last = data.objects.length - 1;
		let to;
		if (max) {
			to = dir > 0 ? last : 0;
		} else {
			to = meta.index + dir;
		}

		if (to > last || to < 0) return false;
		data.objects.splice(to, 0, data.objects.splice(meta.index, 1)[0]);
		meta.index = to;
		onUpdate(data, meta);
		return false;
	}
	duplicate() {
		let { data, meta, onUpdate } = this.props;
		const clone = Object.assign({}, data.objects[meta.index]);
		if (clone.text) {
			clone.position = (clone.position + clone.size) % data.rows;
		} else if (clone.src) {
			clone.top = Math.min(clone.top + 2, 100);
			clone.left = Math.min(clone.left + 2, 100);
		}
		console.log(clone, data.objects[meta.index]);

		data.objects.splice(meta.index, 0, clone);
		meta = { index: meta.index, ...getObjectGeom(clone, data) };
		onUpdate(data, meta);
		return false;
	}
	delete() {
		let { data, meta, onUpdate } = this.props;
		data.objects.splice(meta.index, 1);
		onUpdate(data, false);
		return false;
	}
	handleUpdate() {
		this.props.onUpdate();
	}
	render() {
		const { meta, data } = this.props;
		const { doubleClick } = this.state;
		const object = data.objects[meta.index];

		if (!object) return null;

		const style = {
			top: meta.top + '%',
			left: meta.left + '%',
			width: Math.min(meta.left + meta.width, 100) - meta.left + '%',
			height: Math.min(meta.top + meta.height, 100) - meta.top + '%'
		};
		return (
			<div className={css.root} style={style} onClick={this.onClick}>
				<div className={css.buttons}>
					<Up size={16} onClick={() => this.arrange(1)} />
					<Down size={16} onClick={() => this.arrange(-1)} />
					<Copy size={16} onClick={this.duplicate} />
					<Trash size={16} onClick={this.delete} />
				</div>
				{doubleClick && (object.text || object.text === '') && (
					<div className={css.fields}>
						<Input name="text" value={object.text} onChange={this.onChange} />
						<InputRow>
							<Input type="number" name="speed" value={object.speed} onChange={this.onChange} />
							<Input type="number" name="size" value={object.size} onChange={this.onChange} />
							<Input type="number" name="position" value={object.position} onChange={this.onChange} />
						</InputRow>
						<InputRow>
							<Input type="color" name="color" value={object.color} onChange={this.onChange} />
							<Input
								type="color"
								name="background"
								value={object.background || ''}
								onChange={this.onChange}
								onDelete={this.onDelete}
							/>
						</InputRow>
						<Select
							className={css.blendMode}
							label="blend mode"
							name="blendMode"
							value={object.blendMode}
							onChange={this.onChange}>
							{blendModes.map((bm, bmi) => (
								<option key={`blendmode-${bm}-${bmi}`} value={bm}>
									{bm}
								</option>
							))}
						</Select>
					</div>
				)}
				{object.text && (
					<div className={css.tools}>
						<span className={css.stat}>speed: {object.speed}</span>
						<span className={css.stat}>size: {object.size}</span>
					</div>
				)}
			</div>
		);
	}
}
