import React, { Component, Fragment } from 'react';
import { fromJS } from 'immutable';
import { blendModes, srcToBase64, filePath, publicFilePath } from '../utils';

import classnames from 'classnames';

import Button from './Button';
import Input, { InputRow } from './Input';
import Select from './Select';
import Checkbox from './Checkbox';
import Radio from './Radio';

import css from './Toolbar.css';

const noop = () => {};

const Spacer = ({ children, char }) => (
	<span style={{ verticalAlign: 'bottom', margin: `1.55em 10px 0`, fontSize: '1.25em' }}>
		{children}
		{char}
	</span>
);

const newText = (data, props = {}) =>
	fromJS({
		text: 'new text ',
		size: 2,
		position: Math.round((data.rows || 30) / 2),
		speed: 300 + Math.round(Math.random() * 400),
		...props
	});

const newImage = async src =>
	fromJS({
		src,
		base64: await srcToBase64(src),
		width: 50,
		height: 50,
		top: 25,
		left: 25
	});

export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: []
		};
	}

	toggle(i) {
		let { open } = this.state;
		if (open.includes(i)) {
			open = open.filter(j => j != i);
		} else {
			open.push(i);
		}
		this.setState({ open });
	}

	render() {
		const {
			data,
			slug,
			index,
			activeObject,
			onSelectObject = noop,
			onInsert = noop,
			onChange = noop,
			onVideoExport = noop,
			onPngExport = noop,
			onSvgExport = noop,
			videoExportRunning,
			videoLength
		} = this.props;
		return (
			<div className={css.root}>
				<div style={{ height: '5vh' }} />
				<Checkbox name="running" checked={data.running || false} value={true} onChange={onChange} />
				<div style={{ height: 20 }} />
				<h2>artwork</h2>
				<InputRow>
					<Input name="width" type="number" value={data.width || 0} onChange={onChange} />
					<Spacer char="&times;" />
					<Input type="number" name="height" value={data.height || 0} onChange={onChange} />
				</InputRow>
				<Input type="number" label="number of rows" name="rows" value={data.rows || 0} onChange={onChange} />
				<Input
					label="background color"
					type="color"
					name={['background']}
					value={data.background}
					onChange={onChange}
				/>
				<Input
					label="text color"
					type="color"
					name={['color']}
					value={data.color || '#fff'}
					onChange={onChange}
				/>
				<Input
					type="range"
					label={`fps (${data.frameRate || 30})`}
					name="frameRate"
					min={1}
					max={60}
					value={data.frameRate || 30}
					onChange={onChange}
				/>
				<h2>objects</h2>
				{data.objects &&
					data.objects.map((obj, index) => (
						<div
							className={classnames(css.object, {
								[css.selected]: activeObject && activeObject.index == index
							})}
							key={`obj-${index}`}
							onClick={e => {
								e.stopPropagation();

								onSelectObject(index);
								return false;
							}}>
							{obj.src ? (
								<img style={{ height: '100%' }} src={publicFilePath(obj.src)} />
							) : (
								<p>{obj.text}</p>
							)}
						</div>
					))}
				<Button
					compact
					style={{ width: '40%', padding: 0 }}
					onClick={() => {
						onInsert(['objects', data.objects.length], newText(data));
					}}>
					add text row
				</Button>
				<div style={{ height: 20 }} />
				<h2>insert image</h2>
				{index && index.length
					? index.map((img, index) => (
							<img
								key={`img-index-${index}`}
								onClick={async () => {
									const image = await newImage(img.path);
									if (image) onInsert(['objects', 0], image);
								}}
								style={{ width: 80 }}
								src={publicFilePath(img.path)}
							/>
					  ))
					: null}

				<p>
					add more files to this dropbox folder: <br />
					<a
						style={{ textDecoration: 'underline' }}
						href={`https://www.dropbox.com/home/noanoa/artworks/${slug}`}>{`noanoa/artwork/${slug}`}</a>
					<br />
					formats: jpg, png, svg. <br />
					colorspace: RGB only.
				</p>

				<h2>export</h2>
				<h3>static image</h3>
				<Button onClick={onPngExport}>export png</Button>
				<Button onClick={onSvgExport}>export svg</Button>
				<div style={{ height: 20 }} />
				<h3>video</h3>
				<Button onClick={onVideoExport}>{videoExportRunning ? 'stop' : 'export webm'}</Button>
				<div>
					{videoExportRunning
						? `${Math.floor(videoLength / 30)}.${videoLength % 30} ${
								data.targetLength ? ` / ${data.targetLength}.00` : ''
						  }`
						: ''}
				</div>
				<Input
					label="length (seconds)"
					name="targetLength"
					value={data.targetLength || ''}
					type="number"
					onChange={onChange}
				/>
			</div>
		);
	}
}
