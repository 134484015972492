import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import Analytics from 'react-router-ga';

import { connect, Provider, actions } from './store';

import App from './App';
import './base.css';

ReactDOM.render(
	<Provider>
		<BrowserRouter>
			<Analytics id="UA-137784974-1">
				<App />
			</Analytics>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);
