import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import css from './Button.css';

export class Button extends Component {
	render() {
		const { onClick, href, hover, className, style, children, disabled, invert, wide, compact } = this.props;
		return href ? (
			<a
				className={classnames(css.root, {
					[className]: className,
					[css.withHover]: hover,
					[css.invert]: invert,
					[css.wide]: wide,
					[css.compact]: compact
				})}
				style={style}
				onClick={onClick}
				disabled={disabled}
				href={href}>
				<span>{children}</span>
				{hover && <span>{hover}</span>}
			</a>
		) : (
			<button
				className={classnames(css.root, {
					[className]: className,
					[css.withHover]: hover,
					[css.invert]: invert,
					[css.wide]: wide,
					[css.compact]: compact
				})}
				style={style}
				onClick={onClick}
				disabled={disabled}>
				<span>{children}</span>
				{hover && <span>{hover}</span>}
			</button>
		);
	}
}

export default Button;

Button.propTypes = {
	/**
	 * Button label
	 */
	children: PropTypes.node,

	/**
	 * Determines if this button is disabled
	 */
	disabled: PropTypes.bool,

	/**
	 * adds a custom classname to the button element
	 */
	className: PropTypes.string,

	/**
	 * adds styles to the button element
	 */
	style: PropTypes.object,

	/**
	 * Called when this button is clicked
	 */
	onClick: PropTypes.func
};
