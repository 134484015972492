import React, { Component } from 'react';
import { connect, actions } from '../store';
import marked from 'marked';
import classnames from 'classnames';

marked.setOptions({
	renderer: new marked.Renderer(),
	pedantic: false,
	gfm: true,
	breaks: false,
	sanitize: true,
	smartLists: true,
	smartypants: true
});

import Marquee from '../components/Marquee';

import css from './CodeOfConduct.css';

class CodeOfConduct extends Component {
	componentDidMount() {
		actions.loadCode({ isPublic: true });
	}

	render() {
		const { winWidth, winHeight } = this.props;
		const code = this.props.code.toJS();
		return (
			<div className={css.root}>
				<div style={{ height: (winHeight / 33) * 4 }} />

				<div className={css.code}>
					{code.items &&
						code.items.map((item, index) => (
							<dl key={`item-${index}`} className={css.group}>
								<dt
									className={classnames(css.title, {
										[css.alignLeft]: item.content && marked(item.content).includes('<ul>')
									})}>
									{item.title.split('  ').map((l, tIndex) => (
										<span key={`title-part-${tIndex}`} className={css.titlePart}>
											{l}
										</span>
									))}
								</dt>
								{item.content && (
									<dd className={css.content}>
										<div dangerouslySetInnerHTML={{ __html: marked(item.content) }} />
									</dd>
								)}
							</dl>
						))}
				</div>
				<div style={{ height: (winHeight / 33) * 3 }} />
				<div style={{ position: 'fixed', bottom: 10 }}>
					<Marquee
						text={'noa  noa  código de conducta  noa  noa  '}
						speed={-400}
						referenceWidth={800}
						inSvg={false}
						width={winWidth}
						size={2}
						height={(winHeight / 33) * 2}
					/>
				</div>
			</div>
		);
	}
}

export default connect(({ winWidth, winHeight, code }) => ({ winWidth, winHeight, code }))(CodeOfConduct);
