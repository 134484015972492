import React, { Component } from 'react';

import { FiInfo as Info } from 'react-icons/fi';

import css from './GeneratorInfo.css';

export default class GeneratorInfo extends Component {
	constructor(props) {
		super(props);
		this.state = { open: false };
	}
	render() {
		return (
			<div className={css.root}>
				<div className={css.toggle} onClick={() => this.setState({ open: !this.state.open })}>
					<Info color="white" size={16} />
				</div>
				{this.state.open && (
					<div className={css.pane}>
						<h3>shortcuts</h3>
						<dl>
							<dt>
								⌘ <i>+</i> ]
							</dt>
							<dd>send object forward</dd>

							<dt>
								⌘ <i>+</i> ⌥ <i>+</i> ]
							</dt>
							<dd>send object to the front</dd>

							<dt>
								⌘ <i>+</i> [
							</dt>
							<dd>send object backward</dd>

							<dt>
								⌘ <i>+</i> ⌥ <i>+</i> [
							</dt>
							<dd>send object to the back</dd>
						</dl>
						<h4>text elements</h4>
						<p>(double click to edit text and change props by number)</p>
						<dl>
							<dt>↑ ↓</dt>
							<dd>move up and down by one row</dd>

							<dt>← →</dt>
							<dd>change speed</dd>

							<dt>+ -</dt>
							<dd>scale</dd>
						</dl>
						<h4>images</h4>
						<dl>
							<dt>↑ ↓ ← →</dt>
							<dd>move by 0.5%</dd>

							<dt>
								shift <i>+</i> ↑ ↓ ← →
							</dt>
							<dd>move by 5%</dd>

							<dt>
								⌘ <i>+</i> + -
							</dt>
							<dd>scale proportionally by 0.5%</dd>

							<dt>
								⌘ <i>+</i> ⇧ <i>+</i> + -
							</dt>
							<dd>scale proportionally by 5%</dd>

							<dt>
								⌘ <i>+</i> ↑ ↓ ← →
							</dt>
							<dd>scale by 0.5%</dd>

							<dt>
								⌘ <i>+</i> ⇧ <i>+</i> ↑ ↓ ← →
							</dt>
							<dd>scale by 5%</dd>
						</dl>
					</div>
				)}
			</div>
		);
	}
}
