import React, { Component } from 'react';
import CCapture from '../../lib/ccapture-1.0.9';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Radio, { RadioGroup } from '../../components/Radio';
import { List, Map } from 'immutable';
import { connect, actions } from '../../store';
import Authorize from './Authorize';
import css from './BarScreen.css';
import {
	random,
	formatDate,
	versionedName,
	randomGlyphWidths,
	getGlyphs,
	getGlyphsWidth,
	drawGlyphs,
	drawRepeatedGlyphs,
	FrameRate,
	calculateSpeeds,
	capitalize
} from '../../utils';
import { getSimulatedScreenInfo, drawSimulatedScreen } from './utils';
import memoize from 'memoize-one';

const fontSize = 7;
const screenWidth = 64;
const screenHeight = 48;
const speed = 2;
const gap = 5;
const rightColPos = 64 - 14;
const changePageAfterRotations = 2;

class BarScreen extends Component {
	constructor(props) {
		super(props);
		this.inputChanged = this.inputChanged.bind(this);
		this.lineChanged = this.lineChanged.bind(this);
		this.lineDeleted = this.lineDeleted.bind(this);
		this.lineAdded = this.lineAdded.bind(this);
		this.exportVideo = this.exportVideo.bind(this);
		this.draw = this.draw.bind(this);

		// Memoized functions
		this.getGlyphs = memoize(getGlyphs);
		this.getGlyphsWidth = memoize(getGlyphsWidth);
		this.getIndexOfLongestWidth = memoize(this.getIndexOfLongestWidth);
		this.calculateSpeeds = memoize(calculateSpeeds);

		this.orgCanvasRef = React.createRef();
		this.simulatedCanvasRef = React.createRef();

		this.state = {
			ledCanvasWidth: 'window',
			videoExportRunning: false
		};

		this.pageStart = 0;
		this.frameRate = new FrameRate(15, this.draw);
		this.screenInfo = getSimulatedScreenInfo(screenWidth, screenHeight, 3, 10);

		this.stringsDidUpdate();
	}

	componentDidMount() {
		this.orgCtx = this.orgCanvasRef.current.getContext('2d', { alpha: false });
		this.simulatedCtx = this.simulatedCanvasRef.current.getContext('2d', { alpha: false });
		actions.loadBar(this.props.barName);
		if (this.props.smallFont) {
			this.frameRate.start();
		}
	}

	getBarItems() {
		const propName = this.props.barName;
		return this.props[propName].toJS().items.filter(i => i.price && i.name);
	}

	// Make an array that we can pass to the memoized function. We cannot
	// create this array in every render, because then equality fails, so we
	// create it here when the component changes.
	stringsDidUpdate() {
		const allowedWidths = [280, 490];
		const items = this.getBarItems();
		const leftLines = items.map(item => item.name);
		const rightLines = items.map(item => item.price.toString());
		this.stringList = leftLines.concat(rightLines);
		this.stringListWidths = [].concat(
			Array.from(leftLines, v => randomGlyphWidths(v, allowedWidths)),
			Array.from(rightLines, v => 280)
		);
		this.resetAll();
	}

	// Create counters to hold x for each scrolling line
	resetCounters() {
		const items = this.getBarItems();
		this.counters = [];
		for (let i = 0; i < items.length; i++) {
			this.counters.push(0);
		}
	}

	resetAll() {
		this.resetCounters();
		this.pageStart = 0;
		this.pageRotations = 0;
	}

	getIndexOfLongestWidth(widthList) {
		let maxi = 0;
		for (let i = 0; i < this.counters.length; i++) {
			if (widthList[i] > widthList[maxi]) {
				maxi = i;
			}
		}
		return maxi;
	}

	componentDidUpdate(prevProps, prevState) {
		// Start drawing when the font is loaded
		if (!prevProps.smallFont && this.props.smallFont) {
			this.frameRate.start();
		}

		// Put bar json into cached objects
		const barName = this.props.barName;
		if (prevProps[barName] !== this.props[barName]) {
			this.stringsDidUpdate();
		}
	}

	inputChanged(event, name, value) {
		this.setState({
			[name]: value
		});
	}

	lineChanged(event, name, value) {
		const split = name.split('-');
		const key = split[0];
		const index = parseInt(split[1]);
		const propName = this.props.barName;
		const newBar = this.props[propName].setIn(['items', index, key], value);
		actions.updateBar(this.props.barName, newBar);
		this.saveBar();
	}

	lineDeleted(index) {
		const propName = this.props.barName;
		const newBar = this.props[propName].updateIn(['items'], items => items.delete(index));
		actions.updateBar(this.props.barName, newBar);
		this.savebar();
	}

	lineAdded() {
		const propName = this.props.barName;
		const newBar = this.props[propName].updateIn(['items'], items =>
			items.push(Map({ name: 'name', price: '$0' }))
		);
		actions.updateBar(this.props.barName, newBar);
		this.saveBar();
	}

	saveBar() {
		clearTimeout(this.saveThrottle);
		this.saveThrottle = setTimeout(() => {
			actions.saveBar(this.props.barName);
		}, 2000);
	}

	exportVideo() {
		this.resetAll();
		this.capturer = new CCapture({
			format: 'webm',
			framerate: this.frameRate.fps,
			name: versionedName(this.props.barName)
		});
		this.capturer.start();
		this.frameRate.disable();
		this.setState({
			videoExportRunning: true
		});
	}

	nextPage() {
		const items = this.getBarItems();
		this.pageStart += 6;
		if (this.pageStart >= items.length - 1) {
			this.pageStart = 0;
		}
		this.pageRotations = 0;
		this.resetCounters();
	}

	draw() {
		const items = this.getBarItems();
		const { smallFont } = this.props;
		const scaledFontSize = fontSize * (smallFont.unitsPerEm / smallFont.capHeight);
		const glyphList = this.getGlyphs(this.stringList, smallFont, this.stringListWidths);
		const widthList = this.getGlyphsWidth(glyphList, scaledFontSize, gap);

		// This determines the page we are showing
		// and calculates how many items we have
		const numItems = items.length;
		const pageItems = Math.min(6, numItems - this.pageStart);

		// Draw black background
		this.orgCtx.fillStyle = 'black';
		this.orgCtx.fillRect(0, 0, screenWidth, screenHeight);
		this.orgCtx.fillStyle = 'white';

		const yStart = fontSize;

		// Draw left lines
		for (let i = 0; i < pageItems; i++) {
			const offseti = this.pageStart + i;
			// Text that can fit on the screen does not scroll, but they still
			// have running counters to make it easier to change page.
			const x = widthList[offseti] < rightColPos ? 0 : this.counters[offseti];
			const y = yStart + i * (fontSize + 1);
			// If it fits on the screen
			if (widthList[offseti] < rightColPos) {
				drawGlyphs(this.orgCtx, glyphList[offseti], x, y, scaledFontSize, { round: true });
			} else {
				drawRepeatedGlyphs(this.orgCtx, glyphList[offseti], x, y, scaledFontSize, rightColPos, {
					gap,
					round: true
				});
			}
		}

		// // Draw black background for right lines
		this.orgCtx.fillStyle = 'black';
		this.orgCtx.fillRect(rightColPos, 0, screenWidth, screenHeight);
		this.orgCtx.fillStyle = 'white';

		// Draw right lines
		for (let i = 0; i < pageItems; i++) {
			const offseti = numItems + this.pageStart + i;
			drawGlyphs(this.orgCtx, glyphList[offseti], screenWidth, yStart + i * (fontSize + 1), scaledFontSize, {
				round: true,
				rtl: true,
				monospace: 6
			});
		}

		if (this.state.videoExportRunning) {
			this.capturer.capture(this.orgCanvasRef.current);
		}

		const frame = this.orgCtx.getImageData(0, 0, this.screenInfo.cols, this.screenInfo.rows);
		drawSimulatedScreen(this.screenInfo, frame, this.simulatedCtx);

		// Find speeds based on longest width. You can change this
		// to a specific i to calculate rows speeds based on that.
		const longesti = this.getIndexOfLongestWidth(widthList);
		const speeds = this.calculateSpeeds(speed, widthList, widthList[longesti], true);

		// update all counters
		for (let i = 0; i < this.counters.length; i++) {
			if (speeds[i] === 0) continue;
			this.counters[i] -= speeds[i];
			const rowX = this.counters[i] + widthList[i] + gap;
			if (rowX <= 0) {
				this.counters[i] = rowX;
				if (i === longesti) {
					this.pageRotations++;
					if (this.pageRotations >= changePageAfterRotations) {
						this.nextPage();
						if (this.state.videoExportRunning && this.pageStart === 0) {
							this.capturer.stop();
							this.capturer.save();
							this.frameRate.enable();
							this.setState({
								videoExportRunning: false
							});
						}
					}
				}
			}
		}
	}

	render() {
		const { status } = this.props;
		const items = this.getBarItems();

		const lines = items.map((item, i) => {
			return (
				<div key={`l-${i}`} className={css.lineInput}>
					<Input
						extraClasses={css.leftInput}
						label={`Line ${i + 1}`}
						name={`name-${i}`}
						value={item.name}
						onChange={this.lineChanged}
					/>
					<Input
						extraClasses={css.rightInput}
						label={`Line ${i + 1} price`}
						name={`price-${i}`}
						value={item.price}
						onChange={this.lineChanged}
					/>
					<Button className={css.deletebtn} onClick={() => this.lineDeleted(i)}>
						Delete
					</Button>
				</div>
			);
		});

		return (
			<Authorize>
				<div className={css.root}>
					<h3>
						bar screen <span style={{ opacity: 0.5, marginLeft: '1rem' }}>{status}</span>
					</h3>

					<h2>bar items</h2>
					{lines}
					<Button compact onClick={this.lineAdded}>
						+ line
					</Button>
					<RadioGroup>
						<Radio
							label="Scale to fit window"
							name="ledCanvasWidth"
							value="window"
							checked={this.state.ledCanvasWidth === 'window'}
							onChange={this.inputChanged}
						/>
						<span style={{ marginLeft: 20 }} />
						<Radio
							label="Original Size"
							name="ledCanvasWidth"
							value="original"
							checked={this.state.ledCanvasWidth === 'original'}
							onChange={this.inputChanged}
						/>
					</RadioGroup>
					<Button onClick={this.exportVideo} disabled={this.state.videoExportRunning}>
						{this.state.videoExportRunning ? 'Exporting...' : 'Start export'}
					</Button>
					<div style={{ height: '1rem' }} />
					<canvas width={this.screenInfo.cols} height={this.screenInfo.rows} ref={this.orgCanvasRef} />
					<div className={css[this.state.ledCanvasWidth]}>
						<canvas
							width={this.screenInfo.width}
							height={this.screenInfo.height}
							ref={this.simulatedCanvasRef}
						/>
					</div>
				</div>
			</Authorize>
		);
	}
}

export default connect(({ barArriba, barAbajo, smallFont, status }) => ({ barArriba, barAbajo, smallFont, status }))(
	BarScreen
);
