import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Authorize from './Authorize';
import { connect, actions, stagePlaylistsPath, stageVideosPath } from '../../store';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Radio, { RadioGroup } from '../../components/Radio';
import Checkbox from '../../components/Checkbox';
import Select from '../../components/Select';
import { parentFolder, basename } from '../../utils';
import { fromJS } from 'immutable';
import PlaylistVideo from '../../components/PlaylistVideo';
import css from './Playlist.css';

class Playlist extends Component {
	constructor(props) {
		super(props);
		this.navigateToFolder = this.navigateToFolder.bind(this);
		this.deleteVideo = this.deleteVideo.bind(this);
		this.changeVideo = this.changeVideo.bind(this);
		this.moveVideo = this.moveVideo.bind(this);
		this.state = {
			curFolder: stageVideosPath
		};
	}

	componentDidMount() {
		actions.loadFile(this.filepath());
		actions.loadFolder(stageVideosPath);
	}

	navigateToFolder(path) {
		this.setState({
			curFolder: path
		});
		if (!this.props.folders.get(path)) {
			actions.loadFolder(path);
		}
	}

	filepath() {
		return stagePlaylistsPath + '/' + this.props.filename;
	}

	getPlaylistConfig() {
		return this.props.files.get(this.filepath());
	}

	insertVideo(path) {
		const conf = this.getPlaylistConfig();
		const newConf = conf.updateIn(['videos'], v =>
			v.push(
				fromJS({
					path: path.replace('/noanoa', ''),
					dither: 'bayer',
					brightness: 0,
					fit: 'fill'
				})
			)
		);
		actions.updateFile(this.filepath(), newConf);
	}

	changeVideo(index, name, value) {
		const conf = this.getPlaylistConfig();
		const newConf = conf.updateIn(['videos', index], v => v.merge({ [name]: value }));
		actions.updateFile(this.filepath(), newConf);
	}

	moveVideo(index, amount) {
		const conf = this.getPlaylistConfig();
		const item = conf.getIn(['videos', index]);
		const newConf = conf
			.setIn(['videos', index], conf.getIn(['videos', index + amount]))
			.setIn(['videos', index + amount], item);
		actions.updateFile(this.filepath(), newConf);
	}

	deleteVideo({ index }) {
		const conf = this.getPlaylistConfig();
		const newConf = conf.updateIn(['videos'], v => v.delete(index));
		actions.updateFile(this.filepath(), newConf);
	}

	render() {
		const { files, folders, filename, loading } = this.props;
		const { curFolder } = this.state;
		const playlist = files.get(stagePlaylistsPath + '/' + filename);

		if (!playlist) {
			return <p>Loading</p>;
		}

		const folder = folders.get(curFolder);

		let folderList = null;
		let mp4List = null;

		if (folder) {
			const jsFolder = folder.toJS();
			folderList = jsFolder.entries
				.filter(f => f.type === 'folder')
				.map(f => (
					<li key={f.name}>
						<a
							href="#"
							onClick={e => {
								e.preventDefault();
								this.navigateToFolder(curFolder + '/' + f.name);
							}}>
							{f.name}/
						</a>
					</li>
				));
			mp4List = jsFolder.entries
				.filter(f => f.name.endsWith('.mp4'))
				.map(f => (
					<li key={f.name}>
						<a
							href="#"
							onClick={e => {
								e.preventDefault();
								this.insertVideo(curFolder + '/' + f.name);
							}}>
							{f.name}
						</a>
					</li>
				));
		}

		const playlistVideos = playlist
			.toJS()
			.videos.map((v, i) => (
				<PlaylistVideo
					key={`pv-${i}-${v.path}`}
					index={i}
					onDelete={this.deleteVideo}
					onChange={this.changeVideo}
					onMove={this.moveVideo}
					{...v}
				/>
			));

		return (
			<Authorize>
				<h3>
					<Link to="/admin/stage">&larr; Stage</Link>
				</h3>
				<div className={css.columns}>
					<div className={css.leftCol}>
						<h3>Files</h3>
						{curFolder !== stageVideosPath && (
							<a
								href="#"
								className={css.backBtn}
								onClick={e => {
									e.preventDefault();
									this.navigateToFolder(parentFolder(curFolder));
								}}>
								&larr; back
							</a>
						)}
						<ul className={css.clean}>
							{folderList}
							{mp4List}
							{!folder && <p>loading ...</p>}
						</ul>
					</div>
					<div className={css.rightCol}>
						<h3>Playlist</h3>
						<ul className={css.clean}>{playlistVideos}</ul>
						{playlistVideos.length === 0 && (
							<p>
								this playlist does not have any videos. navigate to a video file on the left and click
								to add to the playlist.
							</p>
						)}
					</div>
				</div>
			</Authorize>
		);
	}
}

export default connect(({ files, folders, loading }) => ({ files, folders, loading }))(Playlist);
