import React, { Component } from 'react';
import { Map, List, fromJS } from 'immutable';

import { connect, actions } from '../../store';
import Authorize from './Authorize';

import Input from '../../components/Input';
import TextArea from '../../components/TextArea';
import Button from '../../components/Button';

import css from './CodeOfConduct.css';

class AdminCode extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onAddItem = this.onAddItem.bind(this);
	}

	componentDidMount() {
		actions.loadCode({ isPublic: true });
	}

	onChange(evt, name, value) {
		const index = parseInt(name[0]);
		const key = name[1];
		const newCode = this.props.code.setIn(['items', index, key], value);
		actions.updateCode(newCode);
		this.save();
	}

	onDelete(index) {
		const newCode = this.props.code.updateIn(['items'], items => items.delete(index));
		actions.updateCode(newCode);
		this.save();
	}

	onAddItem() {
		const newCode = this.props.code.updateIn(['items'], items => items.push(Map({ title: '', content: '' })));
		actions.updateCode(newCode);
		this.save();
	}

	save() {
		clearTimeout(this.saveThrottle);
		this.saveThrottle = setTimeout(() => {
			actions.saveCode(this.props.code);
		}, 1000);
	}

	render() {
		const { winWidth, loading, status } = this.props;
		const code = this.props.code.toJS();

		return (
			<Authorize>
				<div className={css.root}>
					<h3>
						code of conduct editor
						{status ? <span style={{ paddingLeft: '1rem', opacity: 0.5 }}>{status}</span> : null}
					</h3>
					<div>
						{code.items &&
							code.items.map((item, index) => {
								return (
									<div className={css.item} key={`item-${index}`}>
										<div className={css.editor} key={`editor-${index}`}>
											<Input
												key={`editor-${index}-title`}
												inline
												name={[index, 'title']}
												label={`title `}
												value={item.title}
												onChange={this.onChange}
											/>
											<TextArea
												key={`editor-${index}-content`}
												inline
												name={[index, 'content']}
												label={`content (markdown)`}
												value={item.content}
												onChange={this.onChange}
											/>
											<div className={css.actions}>
												<Button className={css.deletebtn} onClick={() => this.onDelete(index)}>
													Delete
												</Button>
											</div>
											<div style={{ height: 10 }} />
										</div>
									</div>
								);
							})}
						<span className={css.addItem} onClick={() => this.onAddItem()}>
							+ add section
						</span>
					</div>
				</div>
			</Authorize>
		);
	}
}
export default connect(({ code, status, winWidth }) => ({ code, status, winWidth }))(AdminCode);
