import React, { Component, Fragment } from 'react';
import equal from 'deep-equal';
import classnames from 'classnames';
import Case from 'case';
import domHelpers from 'dom-helpers';

import { random, shuffle, formatDate } from '../utils';
import MarqueeRows from './MarqueeRows';
import Button from './Button';

import css from './Event.css';

class Event extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hovered: false,
			color: '#fff'
		};

		this.handleOnHover = this.handleOnHover.bind(this);
		this.handleOnOut = this.handleOnOut.bind(this);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return !equal(this.props, nextProps) || this.state.hovered != nextState.hovered;
	}

	handleOnHover() {
		this.setState({ hovered: true });
	}

	handleOnOut() {
		this.setState({ hovered: false });
	}

	render() {
		const { hovered } = this.state;
		const {
			winWidth,
			date,
			pre,
			title,
			post,
			tickets,
			active,
			ticketsUrl = 'https://www.puntoticket.com/evento/Noa-Noa-Club',
			color = '#000',
			background = '#ffff00'
		} = this.props;
		const isHovered = !!hovered || !!active;

		const currentColor = isHovered ? color : 'white';
		const height = winWidth > 600 ? 300 : 180;

		let content = {
			rows: 13,
			background: isHovered ? background : 'black',
			lines: []
		};

		const titles = []
			.concat(title)
			.filter(l => l != null)
			.map(t => ({
				text: t,
				size: 2,
				position: 0,
				speed: random(300, 400),
				color: currentColor
			}));

		const pretitles = []
			.concat(pre)
			.filter(l => l != null)
			.map(p => ({
				text: p,
				size: 1,
				position: 1,
				speed: -200,
				color: currentColor
			}));

		const posttitles = []
			.concat(post)
			.filter(l => l != null)
			.map(p => ({
				text: p,
				size: 1,
				position: 6,
				speed: -300,
				color: currentColor
			}));
		const count = pretitles.length + posttitles.length + titles.length * 2;
		let pos = Math.floor((content.rows - count) / 2);

		content.lines = pretitles
			.concat(titles, posttitles)

			.map(line => {
				line.position = pos;
				pos += line.size;
				return line;
			});
		return (
			<div
				style={{ height, color: currentColor }}
				className={classnames(css.root)}
				onMouseEnter={this.handleOnHover}
				onMouseLeave={this.handleOnOut}
				onTouchStart={this.handleOnHover}
				onTouchEnd={this.handleOnOut}>
				{date && <span className={css.date}>{formatDate(date, true, true)}</span>}
				<MarqueeRows content={content} running={isHovered} height={height} />
				<div className={css.tickets}>
					{tickets &&
						tickets.map((ticket, index) => (
							<Fragment key={`ticket-${index}`}>
								<div
									className={classnames(css.ticket, {
										[css.withHover]: ticket.price && ticket.name
									})}>
									{ticket.price && <span className={css.price}>{ticket.price}</span>}
									{ticket.name && <span className={css.label}>{ticket.name}</span>}
								</div>
								{index < tickets.length - 1 && <span> / </span>}
							</Fragment>
						))}
					{tickets && (
						<Button
							className={css.button}
							href={ticketsUrl}
							hover={ticketsUrl ? null : 'pronto'}
							className={css.button}>
							tickets
						</Button>
					)}
				</div>
			</div>
		);
	}
}

export default Event;
