import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Case from 'case';
import Authorize from './Authorize';
import { connect, actions, stagePlaylistsPath } from '../../store';
import { fromJS } from 'immutable';

import Input from '../../components/Input';

import css from './Home.css';

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = { newArtworkName: '' };
	}

	componentDidMount() {
		actions.loadArtworksIndex();
	}

	render() {
		const { newArtworkName } = this.state;
		const { artworks, loading } = this.props;
		const index = artworks && artworks.toJS().index;
		return (
			<Authorize>
				<div className={css.root}>
					<h3>screens</h3>
					<ul>
						<li>
							<Link to="/admin/stage">stage</Link>
						</li>
						<li>
							<Link to="admin/marquee-screen">marquee screen</Link>
						</li>
						<li>
							<Link to="admin/programming-screen">programming screen</Link>
						</li>
						<li>
							<Link to="admin/bar-arriba-screen">bar arriba screen</Link>
						</li>
						<li>
							<Link to="admin/bar-abajo-screen">bar abajo screen</Link>
						</li>
					</ul>

					<h3>
						<Link to="admin/lineup">content</Link>
					</h3>
					<ul>
						<li>
							<Link to="admin/lineup">lineup</Link>
						</li>
						<li>
							<Link to="admin/bar">bar</Link>
						</li>
						<li>
							<Link to="admin/p.l.u.r.">p.l.u.r.</Link>
						</li>
					</ul>
					<h3>artwork</h3>
					{!index ? (
						<p>loading...</p>
					) : (
						<ul>
							{index
								.filter(e => e.type == 'folder')
								.map((e, i) => (
									<li key={i}>
										&rarr; <Link to={`/admin/artwork/${e.name}`}>{Case.lower(e.name)}</Link>
									</li>
								))}
						</ul>
					)}

					<Input
						label={'new artwork'}
						name="newArtworkName"
						value={newArtworkName}
						onChange={(e, n, v) => this.setState({ [n]: v })}
					/>
					{newArtworkName.length > 3 ? (
						<Link to={`/admin/artwork/${Case.kebab(newArtworkName)}`}>create</Link>
					) : null}
				</div>
			</Authorize>
		);
	}
}

export default connect(({ artworks }) => ({ artworks }))(Home);
