let canvas;
export const svgToCanvas = (svg, width, height, background) =>
	new Promise((resolve, reject) => {
		const xml = new XMLSerializer().serializeToString(svg);
		const svg64 = btoa(xml);
		const b64Start = 'data:image/svg+xml;base64,';
		const svgData = b64Start + svg64;

		// Make img element
		const img = new Image();

		if (!canvas) {
			canvas = document.createElement('canvas');
		}

		if (canvas.width !== width) {
			canvas.width = width;
		}

		if (canvas.height !== height) {
			canvas.height = height;
		}

		const ctx = canvas.getContext('2d');

		if (background) {
			ctx.fillStyle = background;
			ctx.fillRect(0, 0, width, height);
		} else {
			ctx.clearRect(0, 0, width, height);
		}

		img.addEventListener('load', () => {
			ctx.drawImage(img, 0, 0, width, height);
			resolve(canvas);
		});

		img.src = svgData;
	});

export const getSimulatedScreenInfo = (cols, rows, circleRadius, circleSpacing) => {
	const bulletSize = circleRadius * 4;

	return {
		cols,
		rows,
		width: cols * (2 * circleRadius) + (cols + 1) * circleSpacing,
		height: rows * (2 * circleRadius) + (rows + 1) * circleSpacing,
		color: '#f9e14a',
		circleRadius,
		circleSpacing,
		// These are used to position the ● at the right place
		bulletSize,
		bulletxOffset: -(bulletSize * 0.1),
		bulletyOffset: bulletSize * 0.5
	};
};

export const drawSimulatedScreen = (info, frameData, ctx) => {
	const margin = info.circleSpacing + info.circleRadius;
	const moveAmount = 2 * info.circleRadius + info.circleSpacing;

	ctx.font = `${info.bulletSize}px Helvetica`;
	ctx.fillStyle = 'black';
	ctx.fillRect(0, 0, info.width, info.height);
	ctx.translate(margin, margin);

	// Reference rectangles in top left corner
	// ctx.fillStyle = "rgb(0, 255, 0)";
	// ctx.fillRect(0, 0, moveAmount, moveAmount);
	// ctx.fillStyle = "rgb(255, 0, 0)";
	// ctx.fillRect(0, 0, 2 * circleRadius, 2 * circleRadius);

	ctx.fillStyle = info.color;
	for (let i = 0; i < info.cols; i++) {
		for (let j = 0; j < info.rows; j++) {
			const redIndex = (i + j * frameData.width) * 4;
			if (frameData.data[redIndex] > 0 || frameData.data[redIndex + 1] > 0 || frameData.data[redIndex + 2] > 0) {
				ctx.fillText('●', i * moveAmount + info.bulletxOffset, j * moveAmount + info.bulletyOffset);
			}
		}
	}
	ctx.translate(-margin, -margin);
};

export const base64ToArrayBuffer = base64 => {
	const binary_string = window.atob(base64);
	const len = binary_string.length;
	const bytes = new Uint8Array(len);
	for (var i = 0; i < len; i++) {
		bytes[i] = binary_string.charCodeAt(i);
	}
	return bytes.buffer;
};

export const findActiveObjects = (x, y, data) => {
	let indexes = [];
	let activeObjects = [];
	data.objects.forEach((obj, index) => {
		if (obj.text === '') return activeObjects;
		const geom = getObjectGeom(obj, data);
		if (x >= geom.left && x <= geom.right && y >= geom.top && y <= geom.bottom) {
			geom.index = index;
			activeObjects.push(geom);
		}
	});
	return activeObjects;
};

export const getObjectGeom = (obj, data) => {
	const rowHeight = 100 / data.rows;
	if (obj.text) {
		const top = obj.position * rowHeight;
		const bottom = (obj.position + obj.size) * rowHeight;
		return { top, bottom, left: 0, right: 100, width: 100, height: bottom - top };
	} else if (obj.src) {
		const { top, left, width, height } = obj;
		return { top, left, width, height, right: left + width, bottom: top + height };
	}
};
