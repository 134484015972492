import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Case from 'case';
import Authorize from './Authorize';
import { connect, actions, stagePlaylistsPath } from '../../store';
import { fromJS } from 'immutable';

import Input from '../../components/Input';

import css from './NewPlaylist.css';

class NewPlaylist extends Component {
	constructor(props) {
		super(props);
		this.createPlaylist = this.createPlaylist.bind(this);
		this.state = { newPlaylistName: '' };
	}

	filename() {
		return Case.kebab(this.state.newPlaylistName) + '.json';
	}

	path() {
		return stagePlaylistsPath + '/' + this.filename();
	}

	createPlaylist(e) {
		e.preventDefault();
		actions.createFile(this.path(), fromJS({ videos: [] }));
	}

	render() {
		const { newPlaylistName } = this.state;
		const { files, loading } = this.props;

		const fileExists = !!files.get(this.path());

		return (
			<Authorize>
				<div className={css.root}>
					{!fileExists && (
						<div>
							<Input
								label={'new playlist'}
								name="newPlaylistName"
								value={newPlaylistName}
								onChange={(e, n, v) => this.setState({ [n]: v })}
							/>
							{newPlaylistName.length > 3 ? (
								<a href="#" onClick={this.createPlaylist}>
									create
								</a>
							) : null}
						</div>
					)}
					{loading && <p>creating playlist...</p>}
					{fileExists && <Redirect to={`/admin/stage/playlists/${this.filename()}/edit`} />}
				</div>
			</Authorize>
		);
	}
}

export default connect(({ files, loading }) => ({ files, loading }))(NewPlaylist);
