import React, { Component } from 'react';
import memoize from 'memoize-one';
import { connect } from '../store';
import { getGlyphs, getGlyphsWidth, FrameRate, calculateSpeed, random, uid } from '../utils';

class Marquee extends Component {
	constructor(props) {
		super(props);

		this.id = uid();
		this.step = this.step.bind(this);

		// Memoized functions
		this.getGlyphs = memoize(getGlyphs);
		this.getGlyphsWidth = memoize(getGlyphsWidth);
		this.glyphsToPaths = memoize(this.glyphsToPaths);
		this.calculateSpeed = memoize(calculateSpeed);

		this.state = {
			initialStep: random(0, 500)
		};

		if (!props.controlled) {
			this.frameRate = new FrameRate(props.frameRate, this.step);
			this.state.step = 0;
		}
	}

	componentDidMount() {
		if (!this.props.controlled) {
			this.frameRate.start();
		}
	}

	componentWillUnmount() {
		if (!this.props.controlled) {
			this.frameRate.stop();
		}
	}

	step() {
		if (this.props.running) {
			this.setState({
				step: this.state.step + 1
			});
		}
	}

	// This returns an array of groups with paths positioned next to each
	// other which covers the length of width
	glyphsToPaths(glyphs, numTimesRepeat, fontScale, gap, y) {
		const paths = [];

		let x = 0;

		for (let i = 0; i < numTimesRepeat; i++) {
			for (let j = 0; j < glyphs.length; j++) {
				paths.push(
					<g key={`${this.id}-${i}-${j}`} transform={`translate(${x} ${y})`}>
						<path transform={`scale(${fontScale} ${fontScale})`} d={glyphs[j].path.toSVG()} />
					</g>
				);

				x += glyphs[j].advanceWidth * fontScale;
			}
			x += gap;
		}

		return paths;
	}

	render() {
		if (!this.props.glyphs && !this.props.font) return null;

		const {
			controlled,
			glyphs,
			font,
			width,
			height,
			text,
			gap,
			inSvg,
			style,
			color,
			speed,
			offsetY,
			gutter,
			background,
			referenceWidth
		} = this.props;

		const { initialStep } = this.state;

		const fontSize = (height - 2 * gutter) * (font.unitsPerEm / font.capHeight);
		const fontScale = fontSize / font.unitsPerEm;

		const actualGlyphs = glyphs || this.getGlyphs(text, font);
		const glyphsWidth = this.getGlyphsWidth(actualGlyphs, fontSize, gap);

		const numTimesFit = Math.ceil(width / glyphsWidth);
		const numTimesFitTwice = Math.ceil(numTimesFit * 2);

		// Get paths twice as long as width so scrolling leaves no gap
		const paths = this.glyphsToPaths(actualGlyphs, numTimesFitTwice, fontScale, gap, height - gutter);

		// TODO: Get rid of speed / 60 when we have lowered to proper speeds
		// Calculate speeds relative to width of marquee. Pass in props.referenceWidth with number of
		// highest glyphWidth if using multiple marquees and you want the speeds to be more accurate.
		const actualSpeed = this.calculateSpeed(speed / 60, glyphsWidth, referenceWidth || width, true);

		// This is the x within the frame where the first glyphs starts
		const actualStep = (controlled ? this.props.step : this.state.step) + initialStep;
		const firstCharX = (actualStep * actualSpeed) % glyphsWidth;

		// We draw a full set of glyphs before firstCharX because otherwise
		// we would see gaps for speeds > 0
		const x = speed > 0 ? firstCharX - numTimesFit * glyphsWidth : firstCharX;

		// const gStyle = { ...style, fill: color, transform: `translate3d(${x}px,${inSvg ? offsetY : 0}, 0)` };
		const gStyle = { ...style, fill: color };
		const art = (
			<g style={gStyle} transform={`translate(${x} ${inSvg ? offsetY : 0})`}>
				{background && <rect width={numTimesFitTwice * glyphsWidth} height={height} fill={background} />}
				{paths}
			</g>
		);

		return inSvg ? (
			art
		) : (
			<svg
				style={{
					transform: `translate(0, ${offsetY}px)`,
					height: height,
					width: width
				}}>
				{art}
			</svg>
		);
	}
}

Marquee.defaultProps = {
	controlled: false,
	frameRate: 60,
	text: 'noa',
	width: 500,
	height: 150,
	running: true,
	background: false,
	gutter: 0,
	offsetY: 0,
	speed: -2000,
	gap: 0,
	inSvg: true,
	color: 'white'
};

export default connect(({ font }) => ({ font }))(Marquee);
