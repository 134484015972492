import React, { Component, Fragment } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import { actions } from './store';

import { random } from './utils';

import Header from './pages/Header';

import Splash from './pages/Splash.js';
import Live from './pages/Live.js';
import Lineup from './pages/Lineup.js';
import Info from './pages/Info.js';
import Bar from './pages/Bar.js';
import CodeOfConduct from './pages/CodeOfConduct.js';
import AdminGenerator from './pages/admin/Generator.js';
import AdminHome from './pages/admin/Home';
import AdminStage from './pages/admin/Stage';
import AdminMarqueeScreen from './pages/admin/MarqueeScreen';
import AdminProgrammingScreen from './pages/admin/ProgrammingScreen';
import AdminLineup from './pages/admin/Lineup';
import AdminBar from './pages/admin/Bar';
import AdminCode from './pages/admin/CodeOfConduct';
import AdminFontTest from './pages/admin/FontTest';
import AdminBarScreen from './pages/admin/BarScreen';
import AdminPlaylist from './pages/admin/Playlist';
import AdminNewPlaylist from './pages/admin/NewPlaylist';

import css from './App.css';

class App extends Component {
	componentDidMount() {
		window.addEventListener('resize', actions.measure);
		actions.loadFont();
		actions.loadSmallFont();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', actions.measure);
	}

	render() {
		return (
			<div className={css.root}>
				<Header />

				<Switch>
					<Route exact path="/" component={Splash} />
					<Route exact path="/live" component={Live} />
					<Route exact path="/lineup" component={Lineup} />
					<Route exact path="/info" component={Info} />
					{<Route exact path="/bar" component={Bar} />}
					<Route exact path="/p.l.u.r." component={CodeOfConduct} />
					<Route exact path="/admin" component={AdminHome} />
					<Route exact path="/admin/stage" component={AdminStage} />
					<Route exact path="/admin/stage/playlists/new" component={AdminNewPlaylist} />
					<Route
						exact
						path="/admin/stage/playlists/:filename/edit"
						render={({ match }) => <AdminPlaylist filename={match.params.filename} />}
					/>
					<Route exact path="/admin/lineup" component={AdminLineup} />
					<Route exact path="/admin/bar" render={() => <AdminBar barName={'barPapel'} />} />
					<Route exact path="/admin/p.l.u.r." component={AdminCode} />
					<Route exact path="/admin/marquee-screen" component={AdminMarqueeScreen} />
					<Route exact path="/admin/programming-screen" component={AdminProgrammingScreen} />
					<Route
						exact
						path="/admin/bar-abajo-screen"
						render={() => <AdminBarScreen barName={'barAbajo'} />}
					/>
					<Route
						exact
						path="/admin/bar-arriba-screen"
						render={() => <AdminBarScreen barName={'barArriba'} />}
					/>
					<Route exact path="/admin/artwork/:slug" component={AdminGenerator} />
					<Route exact path="/admin/fonttest" component={AdminFontTest} />
				</Switch>
			</div>
		);
	}
}

export default withRouter(App);
