import React, { Component } from 'react';

import { info } from '../data';

import MarqueeRows from '../components/MarqueeRows';

import css from './Info.css';

class Info extends Component {
	render() {
		return (
			<div className={css.root}>
				<div className={css.viewContainer}>
					<div className={css.marqueeContainer}>
						<MarqueeRows content={info} />
					</div>
				</div>
			</div>
		);
	}
}

export default Info;
