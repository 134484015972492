import React, { Component, Fragment } from 'react';
import classnames from 'classnames';

import { connect } from '../store';
import { random, shuffle } from '../utils';

import Marquee from './Marquee';

import css from './MarqueeRows.css';

const defaults = { background: 'black', running: true, rows: 3 };

class MarqueeRows extends Component {
	constructor(props) {
		super(props);
		this.state = {
			running: true
		};
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.measure);
	}

	render() {
		if (!this.props.content) return <p>Loading...</p>;

		const { content, winWidth, winHeight, running, runOnHover } = this.props;
		const { rows, lines, background, style = {} } = content;

		const height = this.props.height || winHeight || 100;
		const rowHeight = height / rows;

		return (
			<div className={css.root}>
				<svg className={css.canvas} style={{ height, background }}>
					{lines.map((line, index) => {
						let { size = 1, position = index + 1, ...rest } = line;
						position = position < 0 ? content.rows - 1 + position : position;
						const shouldRun = content.running !== false && line.running !== false && running !== false;

						return (
							<Marquee
								width={winWidth}
								inSvg={true}
								key={`line-${index}`}
								gutter={rowHeight * 0.2}
								height={size * rowHeight}
								style={style}
								offsetY={position * rowHeight}
								running={shouldRun}
								{...rest}
							/>
						);
					})}
				</svg>
			</div>
		);
	}
}

export default connect(({ winWidth, winHeight }) => ({ winWidth, winHeight }))(MarqueeRows);
