import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { uid } from '../utils';
import css from './Checkbox.css';

export class Checkbox extends Component {
	constructor(props) {
		super(props);
		this.state = { focus: false };
		this.inputRef = React.createRef();
		this._id = uid('checkbox');
		this.handleOnChange = this.handleOnChange.bind(this);
		this.handleOnFocus = this.handleOnFocus.bind(this);
		this.handleOnBlur = this.handleOnBlur.bind(this);
		this.handleToggleClick = this.handleToggleClick.bind(this);
	}

	handleOnChange(event) {
		const { checked } = event.target;
		const { name, value = true, onChange } = this.props;
		const returnValue = checked ? value : false;
		onChange && onChange(event, name, returnValue);
	}

	handleOnFocus() {
		this.setState({ focus: true });
	}

	handleOnBlur() {
		this.setState({ focus: false });
	}
	handleToggleClick() {
		this.inputRef.current.click();
	}
	render() {
		const {
			className,
			checked,
			disabled,
			id = this._id,
			name = '',
			value = '',
			label,
			on = 'on',
			off = 'off'
		} = this.props;

		return (
			<div
				className={classnames(css.root, {
					[className]: className,
					[css.focus]: this.state.focus,
					[css.checked]: checked,
					[css.disabled]: disabled
				})}>
				<span
					onClick={this.handleToggleClick}
					className={classnames(css.toggle, { [css.checkedToggle]: checked })}>
					{checked ? on : off}
				</span>
				<input
					ref={this.inputRef}
					className={css.input}
					checked={checked}
					id={id}
					name={name}
					onChange={this.handleOnChange}
					onFocus={this.handleOnFocus}
					onBlur={this.handleOnBlur}
					type="checkbox"
					value={value}
				/>

				{label && (
					<label className={css.label} htmlFor={id}>
						{label}
					</label>
				)}
			</div>
		);
	}
}

export default Checkbox;

Checkbox.propTypes = {
	/**
	 * Whether the radio is currently checked
	 */
	checked: PropTypes.bool,

	/**
	 * Determines if this radio is disabled
	 */
	disabled: PropTypes.bool,

	/**
	 * String id to set on this radio, useful for labeling
	 */
	id: PropTypes.string,

	/**
	 * A label for the radio option
	 */
	label: PropTypes.string,

	/**
	 * Name to apply to the underlying input
	 */
	name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

	/**
	 * Called when this radio is clicked
	 */
	onChange: PropTypes.func,

	/**
	 * Value to apply to the underlying input
	 */
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};
