import React, { Component, Fragment } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { FiMenu } from 'react-icons/fi';
import { connect, actions } from '../store';

import css from './Header.css';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false
		};
	}
	render() {
		const { open } = this.state;
		return (
			<Fragment>
				<div className={css.toggle} onClick={() => this.setState({ open: !open })}>
					<FiMenu />
				</div>
				<nav className={classnames(css.root, { [css.open]: open })}>
					<ul onClick={() => this.setState({ open: !open })}>
						{this.props.adminToken && (
							<li>
								<NavLink activeClassName={css.active} to="/admin">
									admin
								</NavLink>
							</li>
						)}
						{/*<li>
						<NavLink activeClassName={css.active} to="/live">
							live
						</NavLink>
					</li>*/}
						<li>
							<NavLink activeClassName={css.active} to="/lineup">
								lineup
							</NavLink>
						</li>
						{
							<li>
								<NavLink activeClassName={css.active} to="/bar">
									bar
								</NavLink>
							</li>
						}
						{/*<li>
						<NavLink activeClassName={css.active} to="/info">
							info
						</NavLink>
					</li>*/}
						{
							<li>
								<NavLink activeClassName={css.active} to="/p.l.u.r.">
									p.l.u.r.
								</NavLink>
							</li>
						}
						{this.props.adminToken && (
							<li>
								<Link onClick={() => actions.logout()} to="/">
									logout
								</Link>
							</li>
						)}
					</ul>
				</nav>
				<div className={css.spacer} />
			</Fragment>
		);
	}
}

export default withRouter(connect(({ adminToken }) => ({ adminToken }))(Header));
