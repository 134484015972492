import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { Map, List, fromJS } from 'immutable';

import { connect, actions } from '../../store';
import Authorize from './Authorize';

import Input, { InputRow } from '../../components/Input';
import Checkbox from '../../components/Checkbox';
import Event from '../../components/Event';

import css from './Lineup.css';

class LineupAdmin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			perPage: 10,
			page: 0,
			data: {}
		};
		this.onChange = this.onChange.bind(this);
		this.onDelete = this.onDelete.bind(this);
	}

	componentDidMount() {
		actions.loadLineup();
		this.paged();
	}

	componentDidUpdate() {
		this.paged();
	}

	paged() {
		if (!this.props.location.search) return;
		let tokens = this.props.location.search.slice(1).split(',');
		tokens = tokens.map(t => t.split('='));
		const page = tokens.find(e => e[0] == 'page')[1];
		if (page && page - 1 !== this.state.page) this.setState({ page: page - 1 });
	}

	onChange(evt, name, value) {
		const { lineup } = this.props;
		const loc = ['events'].concat(name);
		if (value === '' || typeof value === 'undefined') {
			actions.updateLineup(lineup.deleteIn(loc));
		} else {
			actions.updateLineup(lineup.setIn(loc, fromJS(value)));
		}
		this.save();
	}

	onDelete(evt, name) {
		const { lineup } = this.props;
		actions.updateLineup(lineup.deleteIn(['events'].concat(name)));
		this.save();
	}
	onAddEvent(index) {
		const { lineup } = this.props;
		const current = lineup.getIn(['events', index]);
		const newEvent = Map({
			date: dayjs(current.get('date'))
				.add(1, 'day')
				.format('YYYY-MM-DD'),
			title: List(['title ']),
			background: '#000000',
			color: '#ffffff',
			visible: false,
			tickets: [{ price: '$5.000' }]
		});
		if (index == 0) {
			actions.updateLineup(lineup.update('events', evts => evts.unshift(newEvent)));
		} else {
			actions.updateLineup(lineup.update('events', evts => evts.insert(index, newEvent)));
		}
	}
	save() {
		clearTimeout(this.saveThrottle);
		this.saveThrottle = setTimeout(() => {
			actions.saveLineup();
		}, 2000);
	}

	render() {
		const { lineup, loading, winWidth, status } = this.props;

		// pagination
		const { perPage, page } = this.state;
		const offset = page * perPage;
		const total = lineup.has('events') ? lineup.get('events').size : 0;
		const pageCount = Math.ceil(total / perPage);
		const prev = page > 0 ? page - 1 : false;

		const next = pageCount > page + 1 ? page + 1 : false;
		const pagination = lineup.has('events') ? (
			<h3>
				{prev !== false && <Link to={`/admin/lineup?page=${prev + 1}`}>&larr;</Link>} page {page + 1} /{' '}
				{pageCount} {next !== false && <Link to={`/admin/lineup?page=${next + 1}`}>&rarr;</Link>}
			</h3>
		) : null;

		const list = lineup.has('events')
			? lineup
					.get('events')
					.map((e, i) => e.set('index', i))
					.slice(offset, offset + perPage)
					.toJS()
			: [];

		return (
			<Authorize>
				<div className={css.root}>
					<h3>
						lineup editor
						{status ? <span style={{ paddingLeft: '1rem', opacity: 0.5 }}>{status}</span> : null}
					</h3>

					{pagination}
					<div>
						{list.map(evt => {
							const index = evt.index;
							const date = dayjs(evt.date).format('YYYY-MM-DD');
							return (
								<div className={css.event} key={`event-${index}`}>
									<span className={css.addEvent} onClick={() => this.onAddEvent(index)}>
										+ add event
									</span>
									<div className={css.editor} key={`editor-${index}`}>
										<h3>{date}</h3>
										<div className={css.actions}>
											<Checkbox
												className={css.toggle}
												on={'visible'}
												off={'hidden'}
												name={[index, 'visible']}
												value={true}
												checked={evt.visible}
												onChange={this.onChange}
											/>

											<span className={css.del} onClick={e => this.onDelete(e, [index])}>
												delete
											</span>
										</div>
										<Input
											name={[index, 'date']}
											label="date"
											value={date}
											type="date"
											onChange={this.onChange}
										/>
										{evt.pre ? (
											<Input
												inline
												name={[index, 'pre']}
												label="pre-title"
												value={evt.pre || evt.pre}
												onChange={this.onChange}
												onDelete={this.onDelete}
											/>
										) : (
											<span
												className={css.addPre}
												onClick={() => this.onChange(null, [index, 'pre'], ' ')}>
												add pre-title
											</span>
										)}
										{evt.title.map((text, titleIndex) => (
											<Input
												key={`editor-${index}-field-${titleIndex}`}
												inline
												name={[index, 'title', titleIndex]}
												label={`title #${titleIndex + 1}`}
												value={text}
												onChange={this.onChange}
												onDelete={this.onDelete}
											/>
										))}
										<span
											className={css.addLine}
											onClick={() => {
												let { title } = evt;
												title.push(' ');
												this.onChange(null, [index, 'title'], title);
											}}>
											add title line
										</span>
										{evt.post ? (
											<Input
												inline
												name={[index, 'post']}
												label="post-title"
												value={evt.post}
												onChange={this.onChange}
												onDelete={this.onDelete}
											/>
										) : (
											<span
												className={css.addPost}
												onClick={() => this.onChange(null, [index, 'post'], ' ')}>
												add post-title
											</span>
										)}
										<div style={{ height: 20 }} />
										<InputRow>
											<Input
												type="color"
												label="text color"
												name={[index, 'color']}
												value={evt.color || 'white'}
												onChange={this.onChange}
											/>
											<Input
												type="color"
												label="background"
												name={[index, 'background']}
												value={evt.background || 'black'}
												onChange={this.onChange}
											/>
										</InputRow>
										<div style={{ paddingBottom: '.5rem' }}>Tickets</div>
										{evt.tickets &&
											evt.tickets.map((ticket, ticketIndex) => {
												return (
													<InputRow key={`event-${index}-ticket-${ticketIndex}`}>
														<Input
															label="price"
															name={[index, 'tickets', ticketIndex, 'price']}
															value={ticket.price || ''}
															onChange={this.onChange}
														/>
														<div style={{ width: '1rem' }} />
														<Input
															label="name"
															name={[index, 'tickets', ticketIndex, 'name']}
															value={ticket.name || ''}
															onChange={this.onChange}
														/>
														<div
															className={css.deleteTicket}
															onClick={e =>
																this.onDelete(e, [index, 'tickets', ticketIndex])
															}>
															delete
														</div>
													</InputRow>
												);
											})}
										<span
											className={css.addTicket}
											onClick={() => {
												let { tickets } = evt;
												tickets.push({ price: '', name: '' });
												this.onChange(null, [index, 'tickets'], tickets);
											}}>
											add ticket
										</span>
										<div style={{ height: 10 }} />
									</div>
									<div className={css.preview}>
										<Event winWidth={winWidth} key={`preview-${index}`} {...evt} />
									</div>
								</div>
							);
						})}
					</div>
					{pagination}
				</div>
			</Authorize>
		);
	}
}
export default connect(({ lineup, status, winWidth }) => ({ lineup, status, winWidth }))(LineupAdmin);
