import rnd from 'fdrandom';
import { isImmutable, fromJS } from 'immutable';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
dayjs.locale('es');

const apiBase = process.env.NOA_NOA_API;
const publicApiBase = process.env.NOA_NOA_CDN;

export const random = (min, max) => {
	const base = Math.random();
	const res = Math.floor(base * (max - min + 1) + min);
	return res;
};

export const shuffle = a => {
	let j, x, i;
	for (i = a.length - 1; i > 0; i--) {
		j = Math.floor(Math.random() * (i + 1));
		x = a[i];
		a[i] = a[j];
		a[j] = x;
	}
	return a;
};

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

export const versionedName = (name, fmt) => {
	return `${name}-${dayjs().format('YYMMDD-HHmmss')}${fmt ? `.${fmt}` : ''}`;
};

export const parentFolder = path => {
	const split = path.split('/');
	split.pop();
	return split.join('/');
};

export const basename = path => {
	const split = path.split('/');
	return split.pop();
};

export const uid = (prefix = 'c') => {
	const r = Math.random()
		.toString(36)
		.substring(2, 16);
	return `${prefix && `${prefix}-`}${r}`;
};

export const blendModes = [
	'normal',
	'multiply',
	'screen',
	'overlay',
	'darken',
	'lighten',
	'color-dodge',
	'color-burn',
	'hard-light',
	'soft-light',
	'difference',
	'exclusion',
	'hue',
	'saturation',
	'color',
	'luminosity'
];

let canvas;

export const filePath = path => {
	const p = /^http/.test(path) ? path : `${apiBase}/file${path.replace(/\s/g, '%20')}`;
	return p;
};

export const publicFilePath = path => (/^http/.test(path) ? path : `${publicApiBase}/file${path}`);
export const folderPath = path => `${apiBase}/folder${path}`;

export const getOpts = token => ({
	headers: {
		'X-Access-Token': token
	}
});

export const postOpts = (token, body) => ({
	method: 'POST',
	body: JSON.stringify(body),
	headers: {
		'Content-Type': 'application/json',
		'X-Access-Token': token
	}
});

export const addAsyncAction = (config, baseAction, callbacks) => {
	const startAction = baseAction + 'Start';
	const successAction = baseAction + 'Success';
	const failAction = baseAction + 'Fail';

	if (typeof callbacks.onSuccess !== 'function') {
		console.error(`Can't create async action for ${baseAction}. onSuccess callback is required`);
		return;
	}
	// Callbacks
	config.actionsCreators[successAction] = callbacks.onSuccess;
	// Default fallbacks for onStart and onFail
	config.actionsCreators[startAction] = callbacks.onStart ? callbacks.onStart : () => ({ loading: true });
	config.actionsCreators[failAction] = callbacks.onFail
		? callbacks.onFail
		: (_, __, res) => ({ loading: false, error: `Error with ${baseAction}` });

	// Default action
	config.actionsCreators[baseAction] = (state, actions, ...args) => {
		actions[startAction](...args);
		callbacks
			.request(state, actions, ...args)
			.then(res => actions[successAction](res, ...args))

			.catch(e => {
				console.log(e);
				actions[failAction](e, ...args);
			});
		return {};
	};
};

export const loadImageData = async data => {
	let objects = data.get('objects') || data.get('images');
	objects = objects.map(async obj => {
		if (obj.has('src') && !obj.has('base64')) {
			const base64 = await srcToBase64(obj.get('src'));
			return obj.set('base64', base64);
		}
		return obj;
	});
	objects = await Promise.all(objects.values());
	return data.set('objects', fromJS(objects));
};

export const removeImageData = data => {
	return data.update('objects', objects =>
		objects.map(obj => {
			obj = obj.delete('base64');
			return obj;
		})
	);
};

export const srcToBase64 = async src => {
	return new Promise((resolve, reject) => {
		const imgData = fetch(filePath(src))
			.then(res => res.blob())
			.then(blob => {
				var reader = new FileReader();
				reader.onload = function() {
					resolve(this.result);
				};
				reader.readAsDataURL(blob);
			});
	});
};

// DATE

export const onAndAfterDate = (list, prop = 'date', day) => {
	let ilist = fromJS(list);
	day = dayjs(day)
		.set('hour', 0)
		.set('minute', 0)
		.subtract(24 - 6, 'hour');
	ilist = ilist.filter(ev => ev.get('visible'));
	ilist = ilist.sort((a, b) => dayjs(a.get(prop)).unix() - dayjs(b.get(prop)).unix());
	ilist = ilist.filter(ev => dayjs(ev.get(prop)).isAfter(day));

	return isImmutable(list) ? ilist : ilist.toJS();
};
export const formatDate = (date, withMonth, useToday, customToday) => {
	const weekdays = ['do', 'lu', 'ma', 'mi', 'ju', 'vi', 'sa'];
	if (isToday(date, customToday) && useToday) {
		return 'hoy';
	} else {
		const d = dayjs(date);
		let out = weekdays[d.day()] + d.format('DDMMM');
		return withMonth ? out.slice(0, 7) : out.slice(0, 4);
	}
};

export const today = customToday =>
	dayjs(customToday)
		.hour(6)
		.minute(0);

export const isToday = (someDate, customToday) => {
	const date = dayjs(someDate);

	return date.isSame(customToday || today(customToday), 'day'); // true
};

export const shallowEquals = (obj1, obj2) =>
	Object.keys(obj1).length === Object.keys(obj2).length &&
	Object.keys(obj1).every(key => obj2.hasOwnProperty(key) && obj1[key] === obj2[key]);

// Fontkit helpers
// -------------------------------------------------------------

const unitsPerEm = 1000;

export const randomGlyphWidth = font => {
	if (Array.isArray(font)) return font[Math.floor(Math.random() * font.length)];
	if (!font || !font.variationAxes.wdth) return random(280, 1400);
	return random(font.variationAxes.wdth.min, font.variationAxes.wdth.max);
};

export const randomGlyphWidths = (text, font, widths = []) => {
	for (var i = 0; i < text.length; i++) {
		widths[i] = widths[i] || randomGlyphWidth(font);
	}
	return widths;
};

export const getGlyph = (char, font, wdth) => {
	let glyph;

	// If font has variations
	if (font.variationAxes.wdth) {
		if (!wdth) {
			wdth = randomGlyphWidth(font);
		}

		const varFont = font.getVariation({ wdth: wdth });
		const layout = varFont.layout(char);
		glyph = layout.glyphs[0];
	} else {
		glyph = font.layout(char).glyphs[0];
	}

	// Flip y axis
	if (!glyph.flipped) {
		glyph.path.commands = glyph.path.commands.map(cmd => ({
			command: cmd.command,
			args: cmd.args.map((arg, i) => (i % 2 === 1 ? unitsPerEm - arg - unitsPerEm : arg))
		}));
		glyph.flipped = true;
	}

	return glyph;
};

// Takes a string or an array of strings and returns an array of glyphs
// or an array of arrays of glyphs. The array is implemented so we can
// memoize-one the function while using for more than one string.
export const getGlyphs = (txt, font, wdth) => {
	const glyphs = [];
	if (Array.isArray(txt)) {
		wdth = wdth || [];
		for (let i = 0; i < txt.length; i++) {
			glyphs[i] = [];
			for (let j = 0; j < txt[i].length; j++) {
				const charWdth = Array.isArray(wdth[i]) ? wdth[i][j % wdth[i].length] : wdth[i];
				glyphs[i].push(getGlyph(txt[i][j], font, charWdth));
			}
		}
	} else {
		for (let i = 0; i < txt.length; i++) {
			const charWdth = Array.isArray(wdth) ? wdth[i % wdth.length] : wdth;

			glyphs.push(getGlyph(txt[i], font, charWdth));
		}
	}
	return glyphs;
};

// Returns the widths of glyphs at a certain font size, or if array
// an array of widths of the glyphs at a certain font size or array of font size
export const getGlyphsWidth = (glyphs, fontSize, gap = 0) => {
	if (Array.isArray(glyphs[0])) {
		const widths = [];
		for (let i = 0; i < glyphs.length; i++) {
			widths.push(getSingleGlyphsWidth(glyphs[i], Array.isArray(fontSize) ? fontSize[i] : fontSize, gap));
		}
		return widths;
	} else {
		return getSingleGlyphsWidth(glyphs, fontSize, gap);
	}
};

const getSingleGlyphsWidth = (glyphs, fontSize, gap = 0) => {
	const fontScale = fontSize / unitsPerEm;
	let w = 0;
	for (let i = 0; i < glyphs.length; i++) {
		w += glyphs[i].advanceWidth * fontScale;
	}
	return w + gap;
};

// Draws a set of glyphs into the given canvas context at a specific x and y
// The glyphs will appear flipped if not created with getGlyphs()
export const drawGlyphs = (ctx, orgGlyphs, x, y, fontSize, { round, rtl, monospace } = {}) => {
	const fontScale = fontSize / unitsPerEm;
	const glyphs = rtl ? orgGlyphs.slice().reverse() : orgGlyphs;
	let curx = rtl ? x - glyphs[0].advanceWidth * fontScale : x;
	if (!glyphs) return;
	for (let i = 0; i < glyphs.length; i++) {
		drawGlyph(ctx, glyphs[i], round ? Math.round(curx) : curx, y, fontSize);
		const move = monospace || glyphs[i].advanceWidth * fontScale;
		curx += rtl ? -move : move;
	}
};

// Like above, but repeats until width
export const drawRepeatedGlyphs = (ctx, glyphs, x, y, fontSize, width, { gap = 0, round }) => {
	if (!glyphs || glyphs.length === 0) {
		return;
	}

	let i = 0;
	let curx = x;
	while (curx < width) {
		drawGlyph(ctx, glyphs[i % glyphs.length], round ? Math.round(curx) : curx, y, fontSize);
		curx += glyphs[i % glyphs.length].advanceWidth * (fontSize / unitsPerEm);
		if (i % glyphs.length === glyphs.length - 1) {
			curx += gap;
		}
		i++;
	}
};

// Draws a glyph into the given canvas context at a specific x and y
// The glyph will appear flipped if not created with getGlyphs()
export const drawGlyph = (ctx, glyph, x, y, fontSize) => {
	ctx.save();
	ctx.translate(x, y);
	ctx.beginPath();

	const scale = fontSize / unitsPerEm; // Hardcode 1000 units per em
	ctx.scale(scale, scale);

	for (let i = 0; i < glyph.path.commands.length; i++) {
		ctx[glyph.path.commands[i].command].apply(ctx, glyph.path.commands[i].args);
	}

	ctx.fill();
	ctx.restore();
};

//  Framerate
// -------------------------------------------------------------

export class FrameRate {
	constructor(fps, draw) {
		this.tick();
		this.fps = fps;
		this.draw = draw;
		this.frameNum = 0;
		this.disabled = false;
		this.running = false;
		this.start = this.start.bind(this);
	}

	start() {
		this.running = true;
		this.frame();
	}

	stop() {
		cancelAnimationFrame(this.raf);
		this.running = false;
	}

	frame() {
		if (this.disabled || this.shouldDraw()) {
			this.draw();
			this.tick();
		}
		if (this.running) {
			this.raf = requestAnimationFrame(this.start);
		}
	}

	disable() {
		this.disabled = true;
	}

	enable() {
		this.disabled = false;
	}

	tick() {
		this.baseTime = Date.now();
		this.frameNum++;
	}

	shouldDraw() {
		return (Date.now() - this.baseTime) / (1000 / this.fps) >= 1;
	}
}

// This function receives a speed (a desired pixel amount to move per frame),
// the width of an item, and the width of the scrolling surface. It then returns
// the actual speed to move the item: If an item is the same size as `referenceWidth`,
// it will move at `speed`. If it is smaller, it will move faster to catch up.
// opts.scaleUp will multiply speed until closest to desired.
export const calculateSpeed = (desiredSpeed, itemWidth, referenceWidth, scaleUp) => {
	const ratio = itemWidth / referenceWidth > 1 ? 1 : itemWidth / referenceWidth;
	const scale = scaleUp && ratio < 1 ? Math.floor(1 / ratio) : 1;
	const scaledSpeed = Math.abs(desiredSpeed) * ratio * scale;
	const actualSpeed = desiredSpeed < 0 ? -scaledSpeed : scaledSpeed;
	return actualSpeed;
};

// Same function but for an array of itemWidths
export const calculateSpeeds = (speed, itemWidths, referenceWidth, scaleUp) => {
	const speeds = [];
	for (let i = 0; i < itemWidths.length; i++) {
		speeds.push(calculateSpeed(speed, itemWidths[i], referenceWidth, scaleUp));
	}

	return speeds;
};
