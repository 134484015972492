import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { uid } from '../utils';
import css from './Select.css';

export class Select extends Component {
	constructor(props) {
		super(props);
		this.state = { focus: false };
		this._id = uid('select');
		this.handleOnChange = this.handleOnChange.bind(this);
		this.handleOnFocus = this.handleOnFocus.bind(this);
		this.handleOnBlur = this.handleOnBlur.bind(this);
	}

	handleOnChange(e) {
		const { onChange, name } = this.props;
		onChange && onChange(e, name, e.target.value);
	}

	handleOnFocus() {
		this.setState({ focus: true });
	}

	handleOnBlur() {
		this.setState({ focus: false });
	}

	render() {
		const {
			children,
			defaultValue,
			disabled,
			error,
			id = this._id,
			invalid = false,
			label,
			name,
			placeholder,
			value,
			className
		} = this.props;

		const rootClasses = classnames(css.root, {
			[css.focus]: this.state.focus,
			[css.withLabel]: label,
			[css.invalid]: invalid,
			[css.disabled]: disabled,
			[className]: className
		});

		return (
			<Fragment>
				<div className={rootClasses}>
					<label className={css.label} htmlFor={id}>
						{label}
					</label>

					<select
						id={id}
						className={css.select}
						disabled={disabled}
						onChange={this.handleOnChange}
						onFocus={this.handleOnFocus}
						onBlur={this.handleOnBlur}
						name={name}
						defaultValue={defaultValue}
						placeholder={placeholder}
						value={value}>
						<option disabled>{placeholder}</option>
						{children}
					</select>
					<span className={css.chevron} />
				</div>
				{invalid && error && (
					<div className={css.errorWrapper}>
						<span id={`error-${id}`} className={css.errorTxt}>
							{error}
						</span>
					</div>
				)}
			</Fragment>
		);
	}
}

export default Select;

Select.defaultProps = {
	placeholder: 'Please choose...'
};

Select.propTypes = {
	/**
	 * Options to render in this component
	 */
	children: PropTypes.node,

	className: PropTypes.string,

	/**
	 * The default value to apply to the underlying select, if the component is intended to be used in an uncontrolled manner. Using the `value` prop instead is preferred
	 */
	defaultValue: PropTypes.string,

	/**
	 * Determines if this select is disabled
	 */
	disabled: PropTypes.bool,

	/**
	 * Validation error message
	 */
	error: PropTypes.string,

	/**
	 * Custom id for the select
	 */
	id: PropTypes.string,

	/**
	 * Determines if this select is invalid
	 */
	invalid: PropTypes.bool,

	/**
	 * Optional label for this components
	 */
	label: PropTypes.string,

	/**
	 * Name of the select
	 */
	name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

	/**
	 * Called when this select is changed
	 */
	onChange: PropTypes.func,

	/**
	 * Placeholder to show inside this select
	 */
	placeholder: PropTypes.string,

	/**
	 * Value to apply to the underlying select
	 */
	value: PropTypes.string
};
