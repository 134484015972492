import React, { Component } from 'react';
import Input from '../../components/Input';
import Radio, { RadioGroup } from '../../components/Radio';
import fontkit from 'fontkit';
import css from './FontTest.css';
import { getSimulatedScreenInfo, drawSimulatedScreen, base64ToArrayBuffer } from './utils';
import { random, getGlyphs, drawGlyphs } from '../../utils';
import memoize from 'memoize-one';

class FontTest extends Component {
	constructor(props) {
		super(props);
		this.inputChanged = this.inputChanged.bind(this);
		this.fontSelected = this.fontSelected.bind(this);
		this.createFont = this.createFont.bind(this);
		this.draw = this.draw.bind(this);

		// Memoized functions
		this.getGlyphs = memoize(getGlyphs);

		this.state = {
			text: 'this is my text',
			fontSize: 28,
			fontWidth: 280,
			screenWidth: 300,
			margin: 2,
			ledCanvasWidth: 'window'
		};

		Object.keys(this.state).forEach(key => {
			const existing = localStorage.getItem(key);
			if (existing != null) this.state[key] = Number.isInteger(this.state[key]) ? parseInt(existing) : existing;
		});

		this.orgCanvasRef = React.createRef();
		this.simulatedCanvasRef = React.createRef();
	}

	componentDidMount() {
		this.orgCtx = this.orgCanvasRef.current.getContext('2d', { alpha: false });
		this.simulatedCtx = this.simulatedCanvasRef.current.getContext('2d', { alpha: false });

		const base64 = localStorage.getItem('fontBase64');
		if (base64) {
			this.createFont(base64);
		}
	}

	inputChanged(event, name, value) {
		localStorage.setItem(name, value);
		this.setState({
			[name]: ['number', 'range'].includes(event.target.type) ? parseInt(value) : value
		});
	}

	fontSelected(event, name, value) {
		const file = event.target.files[0];
		const reader = new FileReader();
		reader.addEventListener(
			'load',
			() => {
				const base64 = reader.result.replace('data:application/octet-stream;base64,', '');
				localStorage.setItem('fontBase64', base64);
				this.createFont(base64);
			},
			false
		);
		reader.readAsDataURL(file);
	}

	createFont(base64) {
		const font = fontkit.create(new Buffer(base64ToArrayBuffer(base64)));
		this.setState({ font }, () => {
			this.draw();
		});
	}

	draw() {
		const { font, fontSize, fontWidth, text, margin } = this.state;

		const scaledFontSize = fontSize * (font.unitsPerEm / font.capHeight);
		const glyphs = this.getGlyphs(text, font, fontWidth);

		this.orgCtx.fillStyle = 'black';
		this.orgCtx.fillRect(0, 0, this.screenInfo.cols, this.screenInfo.rows);

		this.orgCtx.fillStyle = this.screenInfo.color;
		drawGlyphs(this.orgCtx, glyphs, 0, fontSize + margin, scaledFontSize);

		const frame = this.orgCtx.getImageData(0, 0, this.screenInfo.cols, this.screenInfo.rows);
		drawSimulatedScreen(this.screenInfo, frame, this.simulatedCtx);
		window.requestAnimationFrame(this.draw);
	}

	render() {
		const { font, fontWidth, screenWidth, fontSize, margin, ledCanvasWidth } = this.state;

		this.screenInfo = getSimulatedScreenInfo(screenWidth, this.state.fontSize + 2 * this.state.margin, 3, 10);

		return (
			<div className={css.root}>
				<Input label="Load font file" name="font" type="file" onChange={this.fontSelected} />
				<Input
					label={`Font size (${fontSize})`}
					name="fontSize"
					type="range"
					min="2"
					max="32"
					value={fontSize.toString()}
					onChange={this.inputChanged}
				/>
				<Input
					label={`Margin (${margin})`}
					name="margin"
					type="number"
					type="range"
					min="0"
					max="10"
					value={margin.toString()}
					onChange={this.inputChanged}
				/>
				{font && font.variationAxes.wdth && (
					<Input
						label={`Font width (${fontWidth})`}
						name="fontWidth"
						type="number"
						type="range"
						min={280}
						max={1400}
						value={fontWidth.toString()}
						onChange={this.inputChanged}
					/>
				)}
				<Input
					label={`Screen width (${screenWidth})`}
					name="screenWidth"
					type="range"
					min="200"
					max="800"
					step="1"
					value={screenWidth.toString()}
					onChange={this.inputChanged}
				/>
				<Input label="Text to show" name="text" value={this.state.text} onChange={this.inputChanged} />
				<RadioGroup>
					<Radio
						label="Scale to fit window"
						name="ledCanvasWidth"
						value="window"
						checked={ledCanvasWidth === 'window'}
						onChange={this.inputChanged}
					/>
					<span style={{ marginLeft: 20 }} />
					<Radio
						label="Original Size"
						name="ledCanvasWidth"
						value="original"
						checked={ledCanvasWidth === 'original'}
						onChange={this.inputChanged}
					/>
				</RadioGroup>
				<canvas width={this.screenInfo.cols} height={this.screenInfo.rows} ref={this.orgCanvasRef} />
				<div className={css[this.state.ledCanvasWidth]}>
					<canvas
						width={this.screenInfo.width}
						height={this.screenInfo.height}
						ref={this.simulatedCanvasRef}
					/>
				</div>
			</div>
		);
	}
}

export default FontTest;
