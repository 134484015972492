import React, { Component, Fragment } from 'react';
import domHelpers from 'dom-helpers';
import { Link } from 'react-router-dom';

import { actions, connect } from '../store';

import Event from '../components/Event.js';
import Marquee from '../components/Marquee';

import { random, shuffle, onAndAfterDate } from '../utils';

import css from './Lineup.css';

class Lineup extends Component {
	componentDidMount() {
		actions.loadLineup({ isPublic: true });
	}
	render() {
		const { lineup, winWidth, winHeight } = this.props;
		return (
			<div className={css.root}>
				{lineup.events ? (
					onAndAfterDate(lineup.events).map((ev, index) => <Event winWidth={winWidth} key={index} {...ev} />)
				) : (
					<div>...</div>
				)}
				<div style={{ height: (winHeight / 33) * 3 }} />
				<div style={{ position: 'fixed', bottom: 0, zIndex: 1000 }}>
					<Link to="/">
						<Marquee
							speed={-500}
							text="noa noa noa noa noa noa "
							color="white"
							width={winWidth}
							referenceWidth={800}
							height={winHeight / 33}
							inSvg={false}
							running={true}
						/>
					</Link>
					<div style={{ height: winHeight / 33 }} />
				</div>
			</div>
		);
	}
}

export default connect(({ lineup, winWidth, winHeight }) => ({ lineup: lineup.toJS(), winWidth, winHeight }))(Lineup);
