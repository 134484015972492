import React, { Component, Fragment } from 'react';
import { connect } from '../../store';
import Login from './Login';

class Authorize extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.adminToken != prevProps.adminToken) {
			location.reload(true);
		}
	}
	render() {
		const { loginError, adminToken, children } = this.props;
		if (adminToken) {
			return <Fragment>{children}</Fragment>;
		} else {
			return <Login loginError={loginError} />;
		}
	}
}

export default connect(({ adminToken, loginError }) => ({ adminToken, loginError }))(Authorize);
