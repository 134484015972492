import createStore from 'react-waterfall';
import fontkit from 'fontkit';
import domHelpers from 'dom-helpers';
const { height, width, ownerWindow } = domHelpers;
import { fromJS, Map } from 'immutable';

import noa from './fonts/Monoa_Grid_20190508GX.ttf';
import noaSmall from './fonts/Monoa_Pixel_20190508GX.ttf';

import {
	addAsyncAction,
	loadImageData,
	removeImageData,
	getOpts,
	postOpts,
	filePath,
	publicFilePath,
	folderPath,
	onAndAfterDate
} from './utils';

export const stageConfigPath = '/noanoa/stage/stage.json';
export const stagePlaylistsPath = '/noanoa/stage/playlists';
export const stageVideosPath = '/noanoa/stage/videos';
export const artworksPath = `/noanoa/artworks`;
export const artworkPath = (artworkId, file) => `${artworksPath}/${artworkId}${file ? `/${file}` : ''}`;

const lineupPath = '/noanoa/content/lineup.json';
const codePath = '/noanoa/content/codeofconduct.json';
const barPath = barName => `/noanoa/content/${barName.toLowerCase()}.json`;
const timers = {};

const config = {
	initialState: {
		adminToken: localStorage.getItem('adminToken'),
		font: false,
		winHeight: Math.max(1, height(ownerWindow())),
		winWidth: Math.max(1, width(ownerWindow())),
		folders: fromJS({}),
		files: fromJS({}),
		artworks: fromJS({}),
		lineup: fromJS({}),
		barArriba: fromJS({ items: [] }),
		barAbajo: fromJS({ items: [] }),
		barPapel: fromJS({}),
		code: fromJS({}),
		stagePlaylistNames: fromJS([]),
		stagePlaylists: fromJS({})
	},

	actionsCreators: {
		measure: () => ({
			winHeight: Math.max(1, height(ownerWindow())),
			winWidth: Math.max(1, width(ownerWindow()))
		}),

		loadFont: () =>
			fetch(noa)
				.then(res => res.arrayBuffer())
				.then(ab => {
					const font = fontkit.create(new Buffer(ab));
					return { font };
				}),

		loadSmallFont: () =>
			fetch(noaSmall)
				.then(res => res.arrayBuffer())
				.then(ab => {
					const smallFont = fontkit.create(new Buffer(ab));
					return { smallFont };
				}),

		logout: () => {
			localStorage.removeItem('adminToken');
			return { adminToken: false };
		},

		setStatus: (_, actions, status, sticky) => {
			clearTimeout(timers.status);
			if (!sticky) timers.status = setTimeout(() => actions.setStatus(false), 5000);
			return { status };
		}
	}
};
// LOGIN
addAsyncAction(config, 'login', {
	request: (_, __, adminToken) => fetch(folderPath('/noanoa'), getOpts(adminToken)),
	onStart: () => ({ loginError: '' }),
	onFail: (state, actions, res, adminToken) => {
		return { loginError: 'invalid token' };
	},
	onSuccess: (state, actions, res, adminToken) =>
		res.json().then(json => {
			localStorage.setItem('adminToken', adminToken);
			return { adminToken };
		})
});

// GENERAL

addAsyncAction(config, 'createFile', {
	request: ({ adminToken }, actions, path, contents) => fetch(filePath(path), postOpts(adminToken, contents)),
	onSuccess: (state, actions, res, path, contents) => {
		return { files: state.files.set(path, contents), loading: false };
	}
});

addAsyncAction(config, 'loadFile', {
	request: ({ adminToken }, action, path) => fetch(filePath(path), getOpts(adminToken)),
	onSuccess: (state, actions, res, path) =>
		res.json().then(json => {
			return { files: state.files.set(path, fromJS(json)), loading: false };
		})
});

addAsyncAction(config, 'updateFile', {
	request: ({ adminToken }, actions, path, updatedContents) =>
		fetch(filePath(path), postOpts(adminToken, updatedContents)),
	onStart: (state, actions, path, updatedContents) => {
		return { files: state.files.set(path, updatedContents), loading: true };
	},
	onSuccess: (state, actions, res, path, updatedContents) => {
		return { loading: false };
	}
});

addAsyncAction(config, 'loadFolder', {
	request: ({ adminToken }, action, path) => fetch(folderPath(path), getOpts(adminToken)),
	onSuccess: (state, actions, res, path) =>
		res.json().then(json => {
			return { folders: state.folders.set(path, fromJS(json)), loading: false };
		})
});

// EVENTS LINEUP

addAsyncAction(config, 'loadLineup', {
	request: (_, __, { isPublic } = {}) => fetch(isPublic ? publicFilePath(lineupPath) : filePath(lineupPath)),
	onSuccess: (state, actions, res) =>
		res
			.json()
			.then(json => {
				json.events = json.events
					.map(e => Object.assign(e, { datetime: new Date(e.date) }))
					.sort((a, b) => b.datetime - a.datetime);
				json.currentEvents = onAndAfterDate(json.events);
				return json;
			})
			.then(json => {
				return { lineup: fromJS(json), loading: false };
			})
});
config.actionsCreators.updateLineup = (store, actions, lineup) => {
	return { lineup };
};

addAsyncAction(config, 'saveLineup', {
	request: ({ adminToken, lineup }, actions) => fetch(filePath(lineupPath), postOpts(adminToken, lineup)),
	onFail: (state, actions, res) => {
		actions.setStatus('error saving :(');
		return { loading: false };
	},
	onSuccess: (state, actions, res) => {
		actions.setStatus('saved');
		return { loading: false };
	}
});

// CODE OF CONDUCT

addAsyncAction(config, 'loadCode', {
	request: (_, __, { isPublic } = {}) => fetch(isPublic ? publicFilePath(codePath) : filePath(codePath)),
	onSuccess: (state, actions, res) =>
		res.json().then(json => {
			return { code: fromJS(json), loading: false };
		})
});
config.actionsCreators.updateCode = (store, actions, code) => {
	return { code };
};

addAsyncAction(config, 'saveCode', {
	request: (store, action) => fetch(filePath(codePath), postOpts(store.adminToken, store.code)),
	onStart: (state, actions) => {
		actions.setStatus('saving...', true);
		return { loading: true };
	},
	onSuccess: (state, actions) => {
		actions.setStatus('saved');
		return { loading: false };
	}
});

// BAR ITEMS

addAsyncAction(config, 'loadBar', {
	request: ({ adminToken }, actions, barName, { isPublic } = {}) => {
		const getPath = isPublic ? publicFilePath : filePath;
		return fetch(getPath(barPath(barName)), isPublic ? {} : getOpts(adminToken));
	},

	onSuccess: (state, actions, res, barName) =>
		res.json().then(json => {
			return { [barName]: fromJS(json), loading: false };
		})
});

config.actionsCreators.updateBar = (store, actions, barName, bar) => {
	return { [barName]: bar };
};

addAsyncAction(config, 'saveBar', {
	request: (store, actions, barName) =>
		fetch(filePath(`/noanoa/content/${barName.toLowerCase()}.json`), postOpts(store.adminToken, store[barName])),
	onStart: (state, actions) => {
		actions.setStatus('saving...', true);
		return { loading: true };
	},
	onSuccess: (state, actions, res) => {
		actions.setStatus('saved');
		return { loading: false };
	}
});

// ARTWORK

addAsyncAction(config, 'loadArtworksIndex', {
	request: ({ adminToken }, actions, slug) => fetch(folderPath(artworksPath), getOpts(adminToken)),
	onSuccess: ({ artworks }, actions, res, path) => {
		return res.status < 400
			? res.json().then(json => {
					return { artworks: artworks.setIn(['index'], fromJS(json.entries)), loading: false };
			  })
			: {};
	}
});

addAsyncAction(config, 'loadArtworkData', {
	request: ({ adminToken, artworks }, actions, slug) => {
		return fetch(filePath(artworkPath(slug, 'data.json')), getOpts(adminToken));
	},
	onStart: ({ artworks }) => ({ artworks: artworks.merge({ loading: true, isNew: false }) }),
	onFail: ({ artworks }) => ({ artworks: artworks.merge({ loading: false }) }),
	onSuccess: async ({ artworks }, actions, res, slug) => {
		return res.status < 400
			? res
					.json()
					.then(json => fromJS(json))
					.then(loadImageData)
					.then(data => {
						artworks = artworks.set('loading', false);
						artworks = artworks.setIn([slug, 'data'], data);
						return {
							artworks
						};
					})
			: { artworks: artworks.merge({ loading: false, isNew: true }) };
	}
});

addAsyncAction(config, 'saveArtworkData', {
	request: ({ adminToken }, actions, slug, updatedArtwork) => {
		updatedArtwork = removeImageData(updatedArtwork);
		// console.log(updatedArtwork.toJS());
		return fetch(filePath(artworkPath(slug, 'data.json')), postOpts(adminToken, updatedArtwork));
	},
	onStart: ({ artworks }) => {
		actions.setStatus('saving...', true);
		return { artworks: artworks.merge({ isNew: false }) };
	},
	onFail: ({ artworks }) => {
		actions.setStatus('error saving :(');
		return { artworks: artworks.merge({ loading: false }) };
	},
	onSuccess: ({ artworks }, actions, res, slug, updatedArtwork) => {
		return res.json().then(json => {
			actions.setStatus('saved');
			return { artworks: artworks.setIn([slug, 'data'], updatedArtwork).merge({ loading: false }) };
		});
	}
});

addAsyncAction(config, 'loadArtworkAssets', {
	request: ({ adminToken }, actions, slug) => fetch(folderPath(artworkPath(slug)), getOpts(adminToken)),
	onSuccess: ({ artworks }, actions, res, slug) => {
		return res.status < 400
			? res.json().then(json => {
					// console.log(json);
					return { artworks: artworks.setIn([slug, 'index'], fromJS(json)), loading: false };
			  })
			: { loading: false };
	}
});

export const { Provider, connect, actions } = createStore(config);
