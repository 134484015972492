import React, { Component } from 'react';
import { Map } from 'immutable';

import { connect, actions } from '../../store';
import Authorize from './Authorize';

import Input from '../../components/Input';
import Button from '../../components/Button';

import css from './Bar.css';

class AdminBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {}
		};
		this.itemChanged = this.itemChanged.bind(this);
		this.itemDeleted = this.itemDeleted.bind(this);
		this.itemAdded = this.itemAdded.bind(this);
	}

	componentDidMount() {
		actions.loadBar('barPapel', { isPublic: true });
	}

	itemChanged(event, name, value) {
		const gIndex = parseInt(name[0]);
		const key = name[1];
		const index = parseInt(name[2]);
		const propName = this.props.barName;
		const newBar = this.props[propName].setIn(['groups', gIndex, 'items', index, key], value);
		actions.updateBar(this.props.barName, newBar);
		this.saveBar();
	}

	itemDeleted(gIndex, index) {
		const propName = this.props.barName;
		const newBar = this.props[propName].updateIn(['groups', gIndex, 'items'], items => items.delete(index));
		actions.updateBar(this.props.barName, newBar);
		this.saveBar();
	}

	itemAdded(gIndex) {
		const propName = this.props.barName;
		const newBar = this.props[propName].updateIn(['groups', gIndex, 'items'], items =>
			items.push(Map({ name: 'name', description: '', price: '$0' }))
		);
		actions.updateBar(this.props.barName, newBar);
		this.saveBar();
	}

	saveBar() {
		clearTimeout(this.saveThrottle);
		this.saveThrottle = setTimeout(() => {
			actions.saveBar(this.props.barName);
		}, 1000);
	}

	render() {
		const { winWidth, winHeight, menu, status } = this.props;
		return (
			<Authorize>
				<div className={css.root}>
					<h3>
						Menu Editor <span style={{ opacity: 0.5, marginLeft: '1rem' }}>{status}</span>
					</h3>
					<div className={css.menu}>
						{menu.groups &&
							menu.groups.map((group, gIndex) => (
								<dl key={`group-${gIndex}`} className={css.group}>
									<h3 className={css.title}>{group.title}</h3>
									{group.items.map((item, iIndex) => (
										<div className={css.lineInput} key={`item-${iIndex}`}>
											<Input
												key={`editor-${gIndex}-field-${iIndex}-name`}
												name={[gIndex, 'name', iIndex]}
												label={`Item #${iIndex + 1}`}
												value={item.name}
												extraClasses={css.nameField}
												onChange={this.itemChanged}
											/>
											<Input
												key={`editor-${gIndex}-field-${iIndex}-description`}
												name={[gIndex, 'description', iIndex]}
												label={`description`}
												value={item.description}
												extraClasses={css.descriptionField}
												onChange={this.itemChanged}
											/>
											<Input
												key={`editor-${gIndex}-field-${iIndex}-price`}
												extraClasses={css.priceField}
												name={[gIndex, 'price', iIndex]}
												label={`price`}
												value={item.price}
												onChange={this.itemChanged}
											/>
											<Button
												className={css.deletebtn}
												onClick={() => this.itemDeleted(gIndex, iIndex)}>
												Delete
											</Button>
										</div>
									))}
									<Button compact className={css.addbtn} onClick={() => this.itemAdded(gIndex)}>
										+ item
									</Button>
								</dl>
							))}
					</div>
				</div>
			</Authorize>
		);
	}
}
export default connect(({ barPapel, status, winWidth, winHeight }) => ({
	menu: barPapel.toJS(),
	barPapel,
	status,
	winWidth,
	winHeight
}))(AdminBar);
