import React, { Component } from 'react';
import Input from './Input';
import Button from './Button';
import Radio, { RadioGroup } from './Radio';
import { basename } from '../utils';
import css from './PlaylistVideo.css';

class PlaylistVideo extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
	}

	onChange(e, name, value) {
		this.props.onChange(this.props.index, name, value);
	}

	render() {
		const { path, dither, brightness, fit, index, onDelete, onMove } = this.props;
		return (
			<div className={css.root}>
				{index + 1}. {basename(path)}
				<RadioGroup className={css.radioGroup}>
					<Radio
						label="bayer dither"
						name="dither"
						value="bayer"
						variant="small"
						checked={dither === 'bayer'}
						onChange={this.onChange}
						className={css.radio}
					/>
					<Radio
						label="no dither"
						name="dither"
						value="none"
						variant="small"
						checked={dither === 'none'}
						onChange={this.onChange}
						className={css.radio}
					/>
				</RadioGroup>
				<RadioGroup className={css.radioGroup}>
					<Radio
						label="fill"
						name="fit"
						value="fill"
						variant="small"
						checked={fit === 'fill'}
						onChange={this.onChange}
						className={css.radio}
					/>
					<Radio
						label="tiled"
						name="fit"
						value="repeat"
						variant="small"
						checked={fit === 'repeat'}
						onChange={this.onChange}
						className={css.radio}
					/>
				</RadioGroup>
				<Input
					label={'brightness'}
					name="brightness"
					type="range"
					step="0.1"
					min="-3"
					max="3"
					value={brightness.toString()}
					onChange={this.onChange}
				/>
				<Button
					className={css.btn}
					variant="small"
					onClick={e => {
						e.preventDefault();
						onDelete(this.props);
					}}>
					delete
				</Button>
				{index !== 0 && (
					<Button
						className={css.btn}
						variant="small"
						onClick={e => {
							e.preventDefault();
							onMove(index, -1);
						}}>
						move up
					</Button>
				)}
				<Button
					className={css.btn}
					variant="small"
					onClick={e => {
						e.preventDefault();
						onMove(index, 1);
					}}>
					move down
				</Button>
			</div>
		);
	}
}

export default PlaylistVideo;
