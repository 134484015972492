import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SketchPicker } from 'react-color';

import css from './Input.css';
import { uid } from '../utils';

export const InputRow = ({ children, bottom }) => (
	<div className={classnames(css.inputRow, { [css.bottom]: bottom })}>{children}</div>
);

const toRGBA = ({ rgb }) => {
	return `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a || 1})`;
};

export class Input extends Component {
	constructor(props) {
		super(props);
		this.state = {
			focus: false,
			hasValue: false
		};
		this._id = uid('input');
		this.handleOnChange = this.handleOnChange.bind(this);
		this.handleOnFocus = this.handleOnFocus.bind(this);
		this.handleOnBlur = this.handleOnBlur.bind(this);
		this.handleOnKeyPress = this.handleOnKeyPress.bind(this);
		this.handleOnKeyUp = this.handleOnKeyUp.bind(this);
	}

	handleOnChange(event) {
		const { value, type } = event.target;
		const { onChange, name } = this.props;
		onChange(event, name, ['number', 'range'].includes(type) ? parseFloat(value) || 0 : value);
		this.setState({
			hasValue: Boolean(value)
		});
	}

	handleOnFocus() {
		this.setState({ focus: true });
	}

	handleOnBlur() {
		this.setState({
			focus: false
		});
	}
	handleOnKeyPress(event) {
		const { onKeyPress } = this.props;
		onKeyPress && onKeyPress(event);
	}
	handleOnKeyUp(event) {
		const { onSubmit } = this.props;
		if (event.keyCode == 13 && onSubmit) onSubmit();
	}

	render() {
		const {
			id = this._id,
			disabled,
			autocomplete,
			error,
			invalid = false,
			label,
			inline,
			name,
			onKeyPress,
			placeholder,
			required = false,
			style,
			type,
			extraClasses,
			value,
			onChange = () => {}, // prevent rest to verride onChange
			onDelete,
			...rest
		} = this.props;

		const { focus, hasValue } = this.state;

		const isColor = type == 'color';
		const isRange = type == 'range';

		const rootClasses = classnames(css.root, extraClasses, {
			[css.invalid]: invalid,
			[css.inline]: inline,
			[css.disabled]: disabled,
			[css.focus]: focus,
			[css[type]]: css[type],
			[css.colorField]: isColor
		});

		const inputType = isColor ? 'text' : type;
		const inputClasses = classnames(css.input, {
			[css.hasValue]: hasValue,
			[css.colorInput]: isColor
		});

		const finalLabel = isRange ? `${label || name} (${value})` : label || name;

		return (
			<div style={style} className={rootClasses}>
				<label htmlFor={id} className={css.label}>
					<span className={css.labelTxt}>{finalLabel}</span>
					{isColor && (
						<span
							className={css.swatch}
							style={{ backgroundColor: value }}
							onClick={e => onChange(e, name, null)}
						/>
					)}
					<input
						autoComplete={autocomplete}
						className={inputClasses}
						id={id}
						name={name}
						type={inputType}
						value={value}
						placeholder={placeholder}
						disabled={disabled}
						onFocus={this.handleOnFocus}
						onBlur={this.handleOnBlur}
						onChange={this.handleOnChange}
						onKeyPress={this.handleOnKeyPress}
						onKeyUp={this.handleOnKeyUp}
						{...rest}
					/>
					{onDelete && (
						<span onClick={e => onDelete(e, name)} className={css.delete}>
							x
						</span>
					)}
				</label>

				{isColor && this.state.focus && (
					<div style={{ position: 'absolute', zIndex: 1000 }}>
						<SketchPicker
							color={value || ''}
							onChange={(c, e) => {
								console.log(c, e);
								onChange(e, name, toRGBA(c));
							}}
							onChangeComplete={(c, e) => {
								console.log(c, e);
								onChange(e, name, toRGBA(c));
							}}
							presetColors={[
								{ color: 'rgba(255,255,255,1)', title: 'white' },
								{ color: 'rgba(0,0,0,1)', title: 'black' },
								{ color: 'transparent', title: 'transparent' }
							]}
						/>
					</div>
				)}

				{invalid && error && (
					<div className={css.errorWrapper}>
						<span id={`error-${id}`} className={css.errorTxt}>
							{error}
						</span>
					</div>
				)}
			</div>
		);
	}
}

export default Input;

Input.defaultProps = {
	type: 'text',
	autocomplete: 'off',
	onChange: () => {}
};

Input.propTypes = {
	/**
	 * Determines if native browser autocomplete functionality is enabled or not
	 */
	autocomplete: PropTypes.string,

	/**
	 * Determines if this input is disabled
	 */
	disabled: PropTypes.bool,

	/**
	 * Validation error message
	 */
	error: PropTypes.string,

	/**
	 * Input id attribute
	 */
	id: PropTypes.string,

	/**
	 * Determines if this input is invalid
	 */
	invalid: PropTypes.bool,

	/**
	 * Input label
	 */
	label: PropTypes.string,

	/**
	 * Input name attribute. Fired in the onChange event
	 */
	name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

	/**
	 * Called when this input changed
	 */
	onChange: PropTypes.func,

	/**
	 * Called when key is pressed in this input
	 */
	onKeyPress: PropTypes.func,

	/**
	 * Placeholder text for input
	 */
	placeholder: PropTypes.string,

	/**
	 * Extra classes to apply to root
	 */
	extraClasses: PropTypes.string,

	/**
	 * Inline styles to append to the root node of this component
	 */
	style: PropTypes.object,

	/**
	 * Type of input
	 */
	type: PropTypes.string,

	/**
	 * Value of input
	 */
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
