import React, { Component, Fragment } from 'react';
import Authorize from './Authorize';
import { connect, actions, stageConfigPath, stagePlaylistsPath } from '../../store';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Radio, { RadioGroup } from '../../components/Radio';
import Checkbox from '../../components/Checkbox';
import Select from '../../components/Select';
import Case from 'case';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';

import css from './Stage.css';

class Stage extends Component {
	constructor(props) {
		super(props);
		this.state = { config: Map({}) };
		this.buttonClicked = this.buttonClicked.bind(this);
		this.configUpdate = this.configUpdate.bind(this);
	}

	componentDidMount() {
		actions.loadFile(stageConfigPath);
		actions.loadFolder(stagePlaylistsPath);
	}

	getCurrentConfig() {
		const { files } = this.props;
		return files.get(stageConfigPath).merge(this.state.config);
	}

	configUpdate(event, name, value) {
		this.setState({
			config: this.state.config.set(name, value)
		});
	}

	buttonClicked() {
		const conf = this.getCurrentConfig();
		const newConf = conf.set('running', !conf.get('running'));
		this.setState({ config: newConf }, () => {
			actions.updateFile(stageConfigPath, newConf);
			this.setState({ config: Map({}) });
		});
	}

	render() {
		const { files, folders, loading } = this.props;

		const confImm = files.get(stageConfigPath);

		if (!confImm) {
			return (
				<Authorize>
					<div className={css.root}>
						<h3>loading...</h3>
					</div>
				</Authorize>
			);
		}

		const playlistsImm = folders.get(stagePlaylistsPath);
		const playlists = playlistsImm ? playlistsImm.toJS().entries : [];

		const curConfig = this.getCurrentConfig().toJS();
		const readOnly = !!curConfig.running;

		return (
			<Authorize>
				<div className={css.root}>
					<h3>stage screen</h3>
					<h2>input</h2>
					{readOnly && <p style={{ color: 'yellow' }}>stop playback to make changes</p>}
					<RadioGroup>
						<Radio
							disabled={readOnly}
							name="input"
							onChange={this.configUpdate}
							checked={curConfig.input === 'live'}
							value="live"
							label="live"
						/>
						<Radio
							disabled={readOnly}
							name="input"
							onChange={this.configUpdate}
							checked={curConfig.input === 'playlist'}
							value="playlist"
							label="playlist"
						/>
					</RadioGroup>

					<h2>settings</h2>
					{curConfig.input === 'playlist' && (
						<Fragment>
							<Select
								className={css.playlistSelect}
								disabled={readOnly}
								label="select playlist"
								name="playlist"
								value={curConfig.playlist || ''}
								onChange={this.configUpdate}>
								{playlists
									.filter(p => p.name.endsWith('.json'))
									.map(p => (
										<option key={p.name} value={p.name}>
											{Case.lower(p.name.replace('.json', ''))}
										</option>
									))}
							</Select>
							{curConfig.input == 'playlist' && (
								<Fragment>
									<Link className={css.edit} to={`/admin/stage/playlists/${curConfig.playlist}/edit`}>
										Edit {curConfig.playlist}
									</Link>

									<Link className={css.edit} to={`/admin/stage/playlists/new`}>
										new playlist
									</Link>
								</Fragment>
							)}
						</Fragment>
					)}
					{curConfig.input == 'live' && (
						<Fragment>
							<h3>adjustments</h3>
							<Input
								disabled={readOnly}
								type="range"
								label="brightness"
								name="brightness"
								step={0.1}
								min={-3}
								max={3}
								value={curConfig.brightness || 0}
								onChange={this.configUpdate}
							/>
							<h3>dithering</h3>
							<RadioGroup>
								{['none', 'bayer'].map((dither, index) => (
									<Radio
										disabled={readOnly}
										key={`dither-${dither}-${index}`}
										name="dithering"
										onChange={this.configUpdate}
										checked={curConfig.dithering === dither}
										value={dither}
										label={dither.replace('-', ' ')}
									/>
								))}
							</RadioGroup>
						</Fragment>
					)}
					<Button
						wide
						style={
							curConfig.running
								? { background: 'red', color: 'white' }
								: { background: 'green', color: 'white' }
						}
						onClick={this.buttonClicked}>
						{curConfig.running ? 'stop' : 'start'}
					</Button>
				</div>
			</Authorize>
		);
	}
}

export default connect(({ files, folders, loading }) => ({ files, folders, loading }))(Stage);
